import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../../service/service';
import { Address } from "ngx-google-places-autocomplete/objects/address";
import {
    HttpClientModule,
    HttpClient,
    HttpHeaders,
    HttpErrorResponse
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { DomSanitizer } from '@angular/platform-browser';
@Component({
    selector: 'registercomplete',
    templateUrl: './registercomplete.component.html',
})
export class RegisterCompleteComponent2 implements OnInit {
    public safeURL = null;
    public IsMobileNumber1 = true;
    public MobileNumber1 = null;
    public MobileNumber1Message = null;
    public MobileNumber2 = null;
    public MobileNumber3 = null;
    Form_AddUser_Processing = false;
    Form_AddUser: FormGroup;
    constructor(
        private _sanitizer: DomSanitizer,
        private _Http: HttpClient,
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {
        // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl();
    }
    ngOnInit() {

    }


    NavigateToDashboard() {
        var value = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.StoreRegistrationData);
        if (value != null) {

            this._HelperService.IsFormProcessing = true;
            var pData = {
                Task: 'login',
                UserName: value.Email,
                Password: value.Password,
                PlatformCode: 'web',
            };
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, pData);
            _OResponse.subscribe(
                _Response => {
                    this._HelperService.IsFormProcessing = false;
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.Account, _Response.Result);
                        var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.OReqH);
                        _StorageReqH.hcuak = _Response.Result['AccessKey'];
                        _StorageReqH.hcupk = btoa(_Response.Result['PublicKey']);
                        this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.OReqH, _StorageReqH);
                        this._HelperService.NotifySuccess(_Response.Message);

                        if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Merchant) {
                            window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
                        } else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.SubAccount) {
                            window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
                        }

                        else {
                            // this._HelperService.NotifyError('Invalid account. Please contact Support');

                            this._Router.navigate([
                                this._HelperService.AppConfig.Pages.System.Login,

                            ]);

                        }
                    }
                    else {
                        // this._HelperService.NotifyError(_Response.Message);

                        this._Router.navigate([
                            this._HelperService.AppConfig.Pages.System.Login,

                        ]);
                    }
                },
                _Error => {
                    this._HelperService.IsFormProcessing = false;
                    // this._HelperService.HandleException(_Error);

                    this._Router.navigate([
                        this._HelperService.AppConfig.Pages.System.Login,

                    ]);
                });


        } else {
            this._HelperService.NotifyError('Try Again');
        }


    }
}
