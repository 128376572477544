import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as Feather from "feather-icons";
import swal from "sweetalert2";
declare const window: any;

declare var $: any;

import {
  DataHelperService,
  HelperService,
  OList,
  OSelect,
  FilterHelperService,
  OResponse,
} from "../../../../service/service";
import { Observable } from 'rxjs';
import { ThrowStmt } from "@angular/compiler";

@Component({
  selector: "tu-topup",
  templateUrl: "./topup.component.html",
})
export class TUTopupComponent1 implements OnInit {
  public ResetFilterControls: boolean = true;
  CurrentPlan: any = {
    Name: null,
    TypeName: null,
    SellingPrice: null,
    Policy: null,
    EndDate: null


  };
  FreePlan: any = {
    Name: null,
    TypeName: null,
    SellingPrice: null,
    Policy: null,


  };
  BasicPlan: any = {
    Name: null,
    TypeName: null,
    SellingPrice: null,
    Policy: null,
  };
  PremiumPlan: any = {
    Name: null,
    TypeName: null,
    SellingPrice: null,
    Policy: null,
  };

  ContactUs() {

  }
  constructor(
    public _Router: Router,
    public _ActivatedRoute: ActivatedRoute,
    public _FormBuilder: FormBuilder,
    public _HelperService: HelperService,
    public _DataHelperService: DataHelperService,
    public _ChangeDetectorRef: ChangeDetectorRef,
    public _FilterHelperService: FilterHelperService
  ) {
    this._HelperService.showAddNewPosBtn = false;
    this._HelperService.showAddNewStoreBtn = false;
    this._HelperService.showAddNewCashierBtn = false;
    this._HelperService.showAddNewSubAccBtn = false;
    this.type = 1;
    this.typefree = 5;



  }


  type: any = null;
  typefree: any = null;

  LoginCredentials: any = {
    UserName: null,
    Password: null,
    PlatformCode: 'web',
  }
  AllData: any = {}
  ngOnInit() {
    var credentials = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.Credentials);

    this.AllData = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.AllSetUpData)

    console.log("Value", this.AllData)

    this.LoginCredentials.UserName = credentials.UserName;
    this.LoginCredentials.Password = credentials.Password;
    this.Subscription(2, "basictrial");

    this.Monthly();
    // console.log("credentials",credentials)
    Feather.replace();
    this.type = 1;
    $('input[name="subscriptionplan"]').click(function () {
      $(this).tab('show');
    });
    this.GetSubscription();
    var Ref1 = this.generateRandomNumber();
    var Ref = this.generateRandomNumber();

    this._UpdateSubscriptionTransactionReference = 'Sc' + '_' + this._HelperService.UserAccount.AccountId + '_' + Ref1;
    this._SubscriptionTransactionReference = 'Sc' + '_' + this._HelperService.UserAccount.AccountId + '_' + Ref;
    // this.TopUpHistoryList_Setup();
    // this.GetBalance();
    this.GetAccountSubscription();
    // this.GetSubscription();
    $('[data-toggle="tooltip"]').tooltip({
      placement: 'bottom'
    });
  }

  public PaymentId = 0;
  public nameValue2: any = 0;
  public RandomNumber: number = null;
  startTopsubscribe_basic(event, SubscriptionId, SubscriptionKey, amount, id) {
    this.RandomNumber = this._HelperService.Get6DigitRandomNumber();
    var subscribeForm = document.getElementById(id);
    subscribeForm.addEventListener("submit", (event) => {
      event.preventDefault();
      var merchantCode = this._HelperService.AppConfig.MerchantCode;
      var itemId = this._HelperService.AppConfig.MerchantPayMentId;
      var transRef = String(this.RandomNumber);
      var customerName = this._HelperService.AppConfig.ActiveOwnerDisplayName;
      var nameValue: any = amount;
      this.nameValue2 = Number(nameValue) * 100;
      var customerId = this._HelperService.AppConfig.ActiveOwnerId;
      var mode = this._HelperService.AppConfig.MerchantPayMode;
      var redirectUrl = location.href;
      var paymentRequest = {
        merchant_code: merchantCode,
        pay_item_id: itemId,
        txn_ref: transRef,
        amount: this.nameValue2.toString(),
        currency: 566,
        cust_id: customerId,
        cust_name: customerName,
        site_redirect_url: redirectUrl,
        onComplete: (resp) => {
          if (resp.resp != undefined && resp.resp != null && resp.resp != '') {
            if (resp.resp == "00") {
              this.InterSwitchSubscription(SubscriptionId, SubscriptionKey);
            }
            else {
              this._HelperService.NotifyError('Payment failed');
              location.reload();

            }
          }
          else {
            this._HelperService.NotifyError('Payment failed');
            location.reload();
          }

        },
        mode: mode
      };
      if (customerName != "") {
        paymentRequest.cust_name = customerName;
      }
      if (customerId != "") {
        paymentRequest.cust_id = customerId;
      }
      window.webpayCheckout(paymentRequest);
    });

  }
  public InterswitchSubscriptionAccountInformation: any;
  public InterSwitchSubscription(SubscriptionId, SubscriptionKey) {
    this._HelperService.IsFormProcessing = true;
    var PostData = {
      Task: "updatesubscription",
      AccountKey: this._HelperService.AppConfig.ActiveOwnerKey,
      AccountId: this._HelperService.AppConfig.ActiveOwnerId,
      SubscriptionId: SubscriptionId,
      SubscriptionKey: SubscriptionKey,
      PaymentReference: this._SubscriptionTransactionReference,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.AccountSubscription, PostData);
    _OResponse.subscribe(
      _Response => {
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this.InterswitchSubscriptionAccountInformation = _Response.Result
          this._HelperService.NotifySuccess(_Response.Message);
          this.GetAccountSubscription();

          setTimeout(() => {
            this.NavigateToDashboard();
          }, 500);



        } else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
      }
    );
  }


  public UpdatePaymentId = 0;
  public UpdatenameValue2: any = 0;
  public UpdateRandomNumber: number = null;
  IUpdateSubscribe(event, amount) {
    this.UpdateRandomNumber = this._HelperService.Get6DigitRandomNumber();
    var subscribeForm = document.getElementById("Updatesubscribe");
    subscribeForm.addEventListener("submit", (event) => {
      event.preventDefault();
      var merchantCode = this._HelperService.AppConfig.MerchantCode
      var itemId = this._HelperService.AppConfig.MerchantPayMentId;
      var transRef = String(this.UpdateRandomNumber);
      var customerName = this._HelperService.AppConfig.ActiveOwnerDisplayName;
      var nameValue: any = amount;
      this.UpdatenameValue2 = Number(nameValue) * 100;
      var customerId = this._HelperService.AppConfig.ActiveOwnerId;
      var mode = this._HelperService.AppConfig.MerchantPayMode;
      var redirectUrl = location.href;
      var paymentRequest = {
        merchant_code: merchantCode,
        pay_item_id: itemId,
        txn_ref: transRef,
        amount: this.UpdatenameValue2.toString(),
        currency: 566,
        cust_id: customerId,
        cust_name: customerName,
        site_redirect_url: redirectUrl,
        onComplete: (resp) => {
          if (resp.resp != undefined && resp.resp != null && resp.resp != '') {
            if (resp.resp == "00") {
              this.UpdateSubscription();
            }
            else {
              this._HelperService.NotifyError('Payment failed');
              location.reload();

            }
          }
          else {
            this._HelperService.NotifyError('Payment failed');
            location.reload();
          }

        },
        mode: mode
      };
      if (customerName != "") {
        paymentRequest.cust_name = customerName;
      }
      if (customerId != "") {
        paymentRequest.cust_id = customerId;
      }
      window.webpayCheckout(paymentRequest);
    });

  }

  UpdatePlans() {
    this.GetSubscription();
    var Ref1 = this.generateRandomNumber();
    this._UpdateSubscriptionTransactionReference = 'Sc' + '_' + this._HelperService.UserAccount.AccountId + '_' + Ref1;
  }
  FreePlanCheck: any = {
    Amount: null,
    PlanId: null,
    PlanKey: null,
    Text: null,
    TimePeriod: null,
    BasicAmount: null,
    Desc: null

  }

  Monthly() {
    if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 2) {
      this.FreePlanCheck.Amount = '19000';
      this.FreePlanCheck.PlanId = 6;
      this.FreePlanCheck.PlanKey = 'openreward';
      this.FreePlanCheck.Text = "Open Rewards";
      this.FreePlanCheck.TimePeriod = "Per Month";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants on the TUC platform";





    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 3) {
      this.FreePlanCheck.Amount = '95000';
      this.FreePlanCheck.PlanId = 7;
      this.FreePlanCheck.PlanKey = 'closedreward';
      this.FreePlanCheck.Text = "Closed Rewards";
      this.FreePlanCheck.TimePeriod = "Per Month";
      this.FreePlanCheck.Desc = "Customer rewards are exclusive to only your business";





    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 4) {
      this.FreePlanCheck.Amount = '19000';
      this.FreePlanCheck.PlanId = 8;
      this.FreePlanCheck.PlanKey = 'openrewardapi';
      this.FreePlanCheck.Text = "API Rewards Online Open";
      this.FreePlanCheck.TimePeriod = "Per Month";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants with the TUC API integration";






    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 5) {
      this.FreePlanCheck.Amount = '95000';
      this.FreePlanCheck.PlanId = 9;
      this.FreePlanCheck.PlanKey = 'closedrewardapi';
      this.FreePlanCheck.Text = "API  Rewards Online Closed";
      this.FreePlanCheck.TimePeriod = "Per Month";
      this.FreePlanCheck.Desc = "Customer rewards are exclusive to only your business";





    }
    else {
      this.FreePlanCheck.Amount = '19000';
      this.FreePlanCheck.PlanId = 6;
      this.FreePlanCheck.PlanKey = 'openreward';
      this.FreePlanCheck.Text = "Open Rewards";
      this.FreePlanCheck.TimePeriod = "Per Month";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants on the TUC platform";





    }
  }
  Quarterly() {
    if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 2) {
      this.FreePlanCheck.Amount = '54150';
      this.FreePlanCheck.PlanId = 10;
      this.FreePlanCheck.PlanKey = 'openrewardquarterly';
      this.FreePlanCheck.Text = "Open Rewards";
      this.FreePlanCheck.TimePeriod = "Quarterly";
      this.FreePlanCheck.BasicAmount = "57000";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants on the TUC platform";






    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 3) {
      this.FreePlanCheck.Amount = '270750';
      this.FreePlanCheck.PlanId = 11;
      this.FreePlanCheck.PlanKey = 'closedrewardquarterly';
      this.FreePlanCheck.Text = "Closed Rewards";
      this.FreePlanCheck.TimePeriod = "Quarterly";
      this.FreePlanCheck.BasicAmount = "285000";
      this.FreePlanCheck.Desc = "Customer rewards are exclusive to only your business";





    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 4) {
      this.FreePlanCheck.Amount = '54150';
      this.FreePlanCheck.PlanId = 12;
      this.FreePlanCheck.PlanKey = 'openrewardapiquarterly';
      this.FreePlanCheck.Text = "API Rewards Online Open";
      this.FreePlanCheck.TimePeriod = "Quarterly";
      this.FreePlanCheck.BasicAmount = "57000";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants with the TUC API integration";





    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 5) {
      this.FreePlanCheck.Amount = '270750';
      this.FreePlanCheck.PlanId = 13;
      this.FreePlanCheck.PlanKey = 'closedrewardapiquarterly';
      this.FreePlanCheck.Text = "API Rewards Online Closed";
      this.FreePlanCheck.TimePeriod = "Quarterly";
      this.FreePlanCheck.BasicAmount = "285000";
      this.FreePlanCheck.Desc = "Customer rewards are exclusive to only your business";




    }
    else {
      this.FreePlanCheck.Amount = '54150';
      this.FreePlanCheck.PlanId = 6;
      this.FreePlanCheck.PlanKey = 'openreward';
      this.FreePlanCheck.Text = "Open Rewards";
      this.FreePlanCheck.TimePeriod = "Quarterly";
      this.FreePlanCheck.BasicAmount = "57000";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants on the TUC platform";




    }
  }
  semiannual() {
    if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 2) {
      this.FreePlanCheck.Amount = '104880';
      this.FreePlanCheck.PlanId = 14;
      this.FreePlanCheck.PlanKey = 'openrewardsemiannual';
      this.FreePlanCheck.Text = "Open Rewards",
        this.FreePlanCheck.TimePeriod = "Semi - Annual";
      this.FreePlanCheck.BasicAmount = "114000";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants on the TUC platform";




    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 3) {
      this.FreePlanCheck.Amount = '524400';
      this.FreePlanCheck.PlanId = 15;
      this.FreePlanCheck.PlanKey = 'closedrewardsemiannual';
      this.FreePlanCheck.Text = "Closed Rewards";
      this.FreePlanCheck.TimePeriod = "Semi - Annual";
      this.FreePlanCheck.BasicAmount = "570000";
      this.FreePlanCheck.Desc = "Customer rewards are exclusive to only your business";

    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 4) {
      this.FreePlanCheck.Amount = '104880';
      this.FreePlanCheck.PlanId = 16;
      this.FreePlanCheck.PlanKey = 'openrewardapisemiannual';
      this.FreePlanCheck.Text = "API Rewards Online Open";
      this.FreePlanCheck.TimePeriod = "Semi - Annual";
      this.FreePlanCheck.BasicAmount = "114000";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants with the TUC API integration";





    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 5) {
      this.FreePlanCheck.Amount = '524400';
      this.FreePlanCheck.PlanId = 17;
      this.FreePlanCheck.PlanKey = 'closedrewardapisemiannual';
      this.FreePlanCheck.Text = "API Rewards Online Closed";
      this.FreePlanCheck.TimePeriod = "Semi - Annual";
      this.FreePlanCheck.BasicAmount = "570000";
      this.FreePlanCheck.Desc = "Customer rewards are exclusive to only your business";




    }
    else {
      this.FreePlanCheck.Amount = '104880';
      this.FreePlanCheck.PlanId = 6;
      this.FreePlanCheck.PlanKey = 'openreward';
      this.FreePlanCheck.Text = "Open Rewards";
      this.FreePlanCheck.TimePeriod = "Semi - Annual";
      this.FreePlanCheck.BasicAmount = "114000";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants on the TUC platform";




    }
  }
  annual() {
    if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 2) {
      this.FreePlanCheck.Amount = '205200';
      this.FreePlanCheck.PlanId = 18;
      this.FreePlanCheck.PlanKey = 'openrewardannual';
      this.FreePlanCheck.Text = "Open Rewards";
      this.FreePlanCheck.TimePeriod = "Annual";
      this.FreePlanCheck.BasicAmount = "228000";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants on the TUC platform";
    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 3) {
      this.FreePlanCheck.Amount = '1026000';
      this.FreePlanCheck.PlanId = 19;
      this.FreePlanCheck.PlanKey = 'closedrewardannual';
      this.FreePlanCheck.Text = "Closed Rewards";
      this.FreePlanCheck.TimePeriod = "Annual";
      this.FreePlanCheck.BasicAmount = "1140000";
      this.FreePlanCheck.Desc = "Customer rewards are exclusive to only your business";




    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 4) {
      this.FreePlanCheck.Amount = '205200';
      this.FreePlanCheck.PlanId = 20;
      this.FreePlanCheck.PlanKey = 'openrewardapiannual';
      this.FreePlanCheck.Text = "API Rewards Online Open";
      this.FreePlanCheck.TimePeriod = "Annual";
      this.FreePlanCheck.BasicAmount = "228000";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants with the TUC API integration";





    }
    else if (this._HelperService.UserAccount.SystemSubscription.PlanReferenceId == 5) {
      this.FreePlanCheck.Amount = '1026000';
      this.FreePlanCheck.PlanId = 21;
      this.FreePlanCheck.PlanKey = 'closedrewardapiannual';
      this.FreePlanCheck.Text = "API Rewards Online Closed";
      this.FreePlanCheck.TimePeriod = "Annual";
      this.FreePlanCheck.BasicAmount = "1140000"
      this.FreePlanCheck.Desc = "Customer rewards are exclusive to only your business";




    }
    else {
      this.FreePlanCheck.Amount = '19000';
      this.FreePlanCheck.PlanId = 6;
      this.FreePlanCheck.PlanKey = 'openreward';
      this.FreePlanCheck.Text = "Open Rewards";
      this.FreePlanCheck.TimePeriod = "Annual";
      this.FreePlanCheck.BasicAmount = "19000";
      this.FreePlanCheck.Desc = "Customer Rewards are redeemable via different merchants on the TUC platform";




    }
  }

  FreeUpdateSubscriptionDone(ref: any) {
    this.TransactionId = ref.trans
    console.log("ref", ref)

    if (ref != undefined && ref != null && ref != '') {
      if (ref.status == 'success') {
        this.FreeUpdateSubscription();
      }
      else {
        this._HelperService.NotifyError('Payment failed');
      }
    }
    else {
      this._HelperService.NotifyError('Payment failed');
    }
    // this.title = 'Payment successfull';
    // console.log(this.title, ref);
  }

  public FreeUpdateSubscription() {

    this._HelperService.IsFormProcessing = true;
    var PostData = {
      Task: "updatesubscription",
      AccountKey: this._HelperService.AppConfig.ActiveOwnerKey,
      AccountId: this._HelperService.AppConfig.ActiveOwnerId,
      SubscriptionId: this.FreePlanCheck.PlanId,
      SubscriptionKey: this.FreePlanCheck.PlanKey,
      Amount: this.FreePlanCheck.Amount,
      PaymentReference: this._UpdateSubscriptionTransactionReference,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.AccountSubscription, PostData);
    _OResponse.subscribe(
      _Response => {
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this.SubscriptionAccountInformation = _Response.Result

          this._HelperService.NotifySuccess(_Response.Message);
          this.GetAccountSubscription();

          setTimeout(() => {
            this.NavigateToDashboard();
          }, 500);



        } else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
      }
    );
  }


  public _UpdateSubscriptionTransactionReference = null;
  UpdateSubscriptionDone(ref: any) {
    this.TransactionId = ref.trans
    if (ref != undefined && ref != null && ref != '') {
      if (ref.status == 'success') {
        this.UpdateSubscription();
      }
      else {
        this._HelperService.NotifyError('Payment failed');
      }
    }
    else {
      this._HelperService.NotifyError('Payment failed');
    }
    // this.title = 'Payment successfull';
    // console.log(this.title, ref);
  }
  public UpdateSubscriptionAccountInformation: any;
  public UpdateSubscription() {

    this._HelperService.IsFormProcessing = true;
    var PostData = {
      Task: "updatesubscription",
      AccountKey: this._HelperService.AppConfig.ActiveOwnerKey,
      AccountId: this._HelperService.AppConfig.ActiveOwnerId,
      SubscriptionId: this._HelperService.UserAccount.SystemSubscription.PlanReferenceId,
      SubscriptionKey: this._HelperService.UserAccount.SystemSubscription.PlanReferenceKey,
      Amount: this._HelperService.UserAccount.SystemSubscription.Amount,
      PaymentReference: this._UpdateSubscriptionTransactionReference,

    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.AccountSubscription, PostData);
    _OResponse.subscribe(
      _Response => {
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this.SubscriptionAccountInformation = _Response.Result

          this._HelperService.NotifySuccess(_Response.Message);
          this.GetAccountSubscription();

          setTimeout(() => {
            this.NavigateToDashboard();
          }, 500);


          // var UserAccountInformation = this._HelperService.GetStorage(
          //   this._HelperService.AppConfig.Storage.Account
          // );

          // UserAccountInformation.Features = this.UpdateSubscriptionAccountInformation.Features;
          // UserAccountInformation.Permissions = this.UpdateSubscriptionAccountInformation.Permissions;

          // this._HelperService.SaveStorage(
          //   this._HelperService.AppConfig.Storage.Account, UserAccountInformation
          // );


          // if (this.UpdateSubscriptionAccountInformation.Permissions) {
          //   this._HelperService.UserAccount.SystemNamePermissions = this.UpdateSubscriptionAccountInformation.Permissions;

          // } else {
          //   this._HelperService.UserAccount.SystemNamePermissions = [];
          // }
          // var rewardpercentage: any = this.UpdateSubscriptionAccountInformation.Permissions.find(x => x['SystemName'] == 'rewardpercentage');
          // this._HelperService.AppConfig.RewardPercentagePermission = rewardpercentage;

          // var storepermission: any = this.UpdateSubscriptionAccountInformation.Permissions.find(x => x['SystemName'] == 'stores');
          // this._HelperService.AppConfig.StorePermission = storepermission;

          // var terminalpermission: any = this.UpdateSubscriptionAccountInformation.Permissions.find(x => x['SystemName'] == 'terminals');
          // this._HelperService.AppConfig.TerminalPermission = terminalpermission;

          // var cashierpermission: any = this.UpdateSubscriptionAccountInformation.Permissions.find(x => x['SystemName'] == 'cashiers');
          // this._HelperService.AppConfig.CashiersPermission = cashierpermission;

          // var subaccountpermission: any = this.UpdateSubscriptionAccountInformation.Permissions.find(x => x['SystemName'] == 'subaccounts');
          // this._HelperService.AppConfig.SubAccountPermission = subaccountpermission;

          // if (this.UpdateSubscriptionAccountInformation.Features) {
          //   this._HelperService.UserAccount.Permissions = this.UpdateSubscriptionAccountInformation.Features;
          // } else {
          //   this._HelperService.UserAccount.Permissions = [];
          // }
          // var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'loyalty');
          // var RData1: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          // this._HelperService.AppConfig.salesanalytics = RData1;
          // if (RData1) {
          //   this._HelperService.AppConfig.salesanalytics = true;
          // }
          // else {
          //   this._HelperService.AppConfig.salesanalytics = false;
          // }
          // this._HelperService.AppConfig.Loyality = RData;
          // location.reload();


        } else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
      }
    );
  }
  public SubscriptionId: number;
  public SubscriptionKey: string;
  selectPlan(plan: string) {
    if (plan == 'basic') {
      this.SubscriptionId = this.BasicPlan.ReferenceId
      this.SubscriptionKey = this.BasicPlan.ReferenceKey
      console.log('key', this.SubscriptionId)
    }
    if (plan == 'premium') {
      this.SubscriptionId = this.PremiumPlan.ReferenceId
      this.SubscriptionKey = this.PremiumPlan.ReferenceKey
    }
    if (plan == 'free') {
      this.SubscriptionId = this.FreePlan.ReferenceId
      this.SubscriptionKey = this.FreePlan.ReferenceKey
    }
    this._HelperService.CloseModal('Plans');
  }

  closePlanDetail() {
    this._HelperService.OpenModal('Plans');
  }
  closePremiumPlanDetail() {
    this._HelperService.OpenModal('Plans');
  }

  public _TransactionReference = null;
  public _AccountBalanceCreditAmount = 0;
  generateRandomNumber() {
    var TrRef = Math.floor(100000 + Math.random() * (999999 + 1 - 100000));
    return TrRef;
  }
  OpenPaymentOptions() {
    this._AccountBalanceCreditAmount = 0;
    var Ref = this.generateRandomNumber();
    this._TransactionReference = 'Tp' + '_' + this._HelperService.UserAccount.AccountId + '_' + Ref;
    this._HelperService.OpenModal('Form_AddUser_Content');
  }
  paymentDone(ref: any) {
    this.TransactionId = ref.trans
    if (ref != undefined && ref != null && ref != '') {
      if (ref.status == 'success') {
        this.CreditAmount();
      }
      else {
        this._HelperService.NotifyError('Payment failed');
      }
    }
    else {
      this._HelperService.NotifyError('Payment failed');
    }
    // this.title = 'Payment successfull';
    // console.log(this.title, ref);
  }

  public TransactionId
  public CreditAmount() {
    this._HelperService.IsFormProcessing = true;
    var PostData = {
      Task: "topupaccount",
      AccountKey: this._HelperService.AppConfig.ActiveOwnerKey,
      AccountId: this._HelperService.AppConfig.ActiveOwnerId,
      Amount: this._AccountBalanceCreditAmount,
      PaymentReference: this._TransactionReference,
      TransactionId: this.TransactionId
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Subscription, PostData);
    _OResponse.subscribe(
      _Response => {
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._AccountBalanceCreditAmount = 0;
          this._TransactionReference = null;
          this._HelperService.NotifySuccess("Account credited");
          this.GetBalance();


        } else {
          this._HelperService.NotifySuccess(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
      }
    );
  }
  paymentCancel() {
    console.log('payment failed');
  }

  //SUbscription Payment

  public _SubscriptionTransactionReference = null;

  Credentials

  SubscriptionDone(ref: any, SubscriptionId, SubscriptionKey) {
    this.TransactionId = ref.trans
    if (ref != undefined && ref != null && ref != '') {
      if (ref.status == 'success') {
        this.Subscription(SubscriptionId, SubscriptionKey);
      }
      else {
        this._HelperService.NotifyError('Payment failed');
      }
    }
    else {
      this._HelperService.NotifyError('Payment failed');
    }
    // this.title = 'Payment successfull';
    // console.log(this.title, ref);
  }
  OnBoardSubId: number;
  OnBoardSubKey: number;

  public SubscriptionAccountInformation: any;
  public Subscription(SubscriptionId, SubscriptionKey) {
    this.OnBoardSubId = SubscriptionId;
    this.OnBoardSubKey = SubscriptionKey;

    this._HelperService.IsFormProcessing = true;
    var PostData = {
      Task: "onboardmerchant_st6",
      SubscriptionId: SubscriptionId,
      SubscriptionKey: SubscriptionKey,
      PaymentReference: this._SubscriptionTransactionReference,
    };
    var Request = this.CreateRequestJson();

    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, Request);
    _OResponse.subscribe(
      _Response => {
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {


          this.LoginCredentials = _Response.Result
          setTimeout(() => {
            this.NavigateToDashboard();
          }, 500);
          // console.log("LoginCredentials", this.LoginCredentials)

          // this._HelperService.NotifySuccess(_Response.Message);
          this._HelperService.NotifySuccess('Welcome! Your merchant registered successfully');



        } else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
      }
    );
  }
  Response: any = {}

  CreateRequestJson(): void {
    var Ref = this.generateRandomNumber();
    this._SubscriptionTransactionReference = 'freeSubscription' + '_' + Ref;
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)
    this.AllData = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.AllSetUpData)

    var AccountDetail = this._HelperService.OnBoardMerchant
    AccountDetail.Task = 'onboardmerchant_st6',
      AccountDetail.Name = this.AllData.Name,
      AccountDetail.DisplayName = this.AllData.Name,
      AccountDetail.DisplayName = this.AllData.Name,
      AccountDetail.ReferralCode = this.AllData.ReferralCode
    AccountDetail.StatusCode = this.AllData.StatusCode,
      AccountDetail.Source = 'Merchant',
      AccountDetail.Reference = this.Response.Result.Reference,
      AccountDetail.Host = this._HelperService.AppConfig.Host

    AccountDetail.Address = {
      Latitude: this.AllData.Address.Latitude,
      Longitude: this.AllData.Address.Latitude,
      Address: this.AllData.Address.Address,
      CountryName: this.AllData.Address.CountryName,
      StateName: this.AllData.Address.StateName,
      CityName: this.AllData.Address.CityName
    };
    AccountDetail.SubscriptionId = this.OnBoardSubId,
      AccountDetail.SubscriptionKey = this.OnBoardSubKey,
      AccountDetail.PaymentReference = this._SubscriptionTransactionReference,


      AccountDetail.Categories = [];
    AccountDetail.Categories = this.AllData.Categories;
    console.log("AccountDetail.Categories", AccountDetail.Categories);
    return AccountDetail;
  }

  FreePlanActivate() {
    this._HelperService.IsFormProcessing = true;
    var PostData = {
      Task: "updatesubscription",
      AccountKey: this._HelperService.AppConfig.ActiveOwnerKey,
      AccountId: this._HelperService.AppConfig.ActiveOwnerId,
      SubscriptionId: 1,
      SubscriptionKey: 'thankucashmerchantfree',
      PaymentReference: this._SubscriptionTransactionReference,
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.AccountSubscription, PostData);
    _OResponse.subscribe(
      _Response => {
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._HelperService.NotifySuccess(_Response.Message);
          this._HelperService.CloseModal('FreePlans');
          this.SubscriptionAccountInformation = _Response.Result
          this.GetAccountSubscription();

          var UserAccountInformation = this._HelperService.GetStorage(
            this._HelperService.AppConfig.Storage.Account
          );

          UserAccountInformation.Features = this.SubscriptionAccountInformation.Features;
          UserAccountInformation.Permissions = this.SubscriptionAccountInformation.Permissions;

          this._HelperService.SaveStorage(
            this._HelperService.AppConfig.Storage.Account, UserAccountInformation
          );


          if (this.SubscriptionAccountInformation.Permissions) {
            this._HelperService.UserAccount.SystemNamePermissions = this.SubscriptionAccountInformation.Permissions;

          } else {
            this._HelperService.UserAccount.SystemNamePermissions = [];
          }
          var rewardpercentage: any = this.SubscriptionAccountInformation.Permissions.find(x => x['SystemName'] == 'rewardpercentage');
          this._HelperService.AppConfig.RewardPercentagePermission = rewardpercentage;

          var storepermission: any = this.SubscriptionAccountInformation.Permissions.find(x => x['SystemName'] == 'stores');
          this._HelperService.AppConfig.StorePermission = storepermission;

          var terminalpermission: any = this.SubscriptionAccountInformation.Permissions.find(x => x['SystemName'] == 'terminals');
          this._HelperService.AppConfig.TerminalPermission = terminalpermission;

          var cashierpermission: any = this.SubscriptionAccountInformation.Permissions.find(x => x['SystemName'] == 'cashiers');
          this._HelperService.AppConfig.CashiersPermission = cashierpermission;

          var subaccountpermission: any = this.SubscriptionAccountInformation.Permissions.find(x => x['SystemName'] == 'subaccounts');
          this._HelperService.AppConfig.SubAccountPermission = subaccountpermission;

          if (this.SubscriptionAccountInformation.Features) {
            this._HelperService.UserAccount.Permissions = this.SubscriptionAccountInformation.Features;
          } else {
            this._HelperService.UserAccount.Permissions = [];
          }
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'loyalty');
          var RData1: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          this._HelperService.AppConfig.salesanalytics = RData1;
          if (RData1) {
            this._HelperService.AppConfig.salesanalytics = true;
          }
          else {
            this._HelperService.AppConfig.salesanalytics = false;
          }
          this._HelperService.AppConfig.Loyality = RData;
          location.reload();


        } else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
      }
    );
  }
  //end Subscription

  SubscriptionpaymentCancel() {
    console.log('payment failed');
    var Ref1 = this.generateRandomNumber();
    var Ref = this.generateRandomNumber();

    this._UpdateSubscriptionTransactionReference = 'Sc' + '_' + this._HelperService.UserAccount.AccountId + '_' + Ref1;
    this._SubscriptionTransactionReference = 'Sc' + '_' + this._HelperService.UserAccount.AccountId + '_' + Ref;

  }
  public _AccountBalance =
    {
      Credit: 0,
      Debit: 0,
      Balance: 0
    }
  public GetBalance() {
    this._HelperService.IsFormProcessing = true;
    var pData = {
      Task: 'getaccountbalance',
      AccountKey: this._HelperService.AppConfig.ActiveOwnerKey,
      AccountId: this._HelperService.AppConfig.ActiveOwnerId,
      Source: this._HelperService.AppConfig.TransactionSource.Merchant
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Subscription, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._AccountBalance = _Response.Result;
          // this._AccountBalance.Balance = _Response.Result.Balance / 100;
          // this._AccountBalance.Credit = _Response.Result.Credit / 100;
          // this._AccountBalance.Debit = _Response.Result.Debit / 100;
        } else {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      }
    );
  }

  OpenTopUpHistory() {
    this._HelperService.OpenModal('TopupHistory')
    this.TopUpHistoryList_Setup();
  }
  public TopUpHistoryList_Config: OList;
  TopUpHistoryList_Setup() {
    this.TopUpHistoryList_Config = {
      Id: null,
      Task: this._HelperService.AppConfig.Api.ThankUCash.GetTopupHistory,
      Location: this._HelperService.AppConfig.NetworkLocation.V3.Subscription,
      Title: "Available Stores",
      StatusType: "default",
      AccountKey: this._HelperService.AppConfig.ActiveOwnerKey,
      AccountId: this._HelperService.AppConfig.ActiveOwnerId,
      Sort:
      {
        SortDefaultName: null,
        SortDefaultColumn: 'TransactionDate',
        SortName: null,
        SortColumn: null,
        SortOrder: 'desc',
        SortOptions: [],
      },

      TableFields: [
        {
          DisplayName: "Reference Number",
          SystemName: "PaymentReference",
          DataType: this._HelperService.AppConfig.DataType.Text,
          Class: "",
          Show: true,
          Search: true,
          Sort: false,
          ResourceId: null,
          NavigateField: "ReferenceKey"
        },


        {
          DisplayName: "Amount",
          SystemName: "Amount",
          DataType: this._HelperService.AppConfig.DataType.Number,
          Class: "text-right",
          Show: true,
          Search: false,
          Sort: true,
          ResourceId: null,
          NavigateField: "ReferenceKey"
        },
        {
          DisplayName: "Cashiers",
          SystemName: "Cashiers",
          DataType: this._HelperService.AppConfig.DataType.Number,
          Class: "text-right",
          Show: true,
          Search: false,
          Sort: false,
          ResourceId: null,
          NavigateField: "ReferenceKey",

        },
        {
          DisplayName: this._HelperService.AppConfig.CommonResource.CreateDate,
          SystemName: "TransactionDate",
          DataType: this._HelperService.AppConfig.DataType.Date,
          Class: "td-date text-right",
          Show: true,
          Search: false,
          Sort: true,
          ResourceId: null,
          NavigateField: "ReferenceKey",
        },
      ]
    };
    this.TopUpHistoryList_Config = this._DataHelperService.List_Initialize(
      this.TopUpHistoryList_Config
    );

    this._HelperService.Active_FilterInit(
      this._HelperService.AppConfig.FilterTypeOption.Merchant,
      this.TopUpHistoryList_Config
    );

    this.TopUpHistoryList_GetData();
  }
  TopUpHistoryList_ToggleOption(event: any, Type: any) {

    if (event != null) {
      for (let index = 0; index < this.TopUpHistoryList_Config.Sort.SortOptions.length; index++) {
        const element = this.TopUpHistoryList_Config.Sort.SortOptions[index];
        if (event.SystemName == element.SystemName) {
          element.SystemActive = true;
        }
        else {
          element.SystemActive = false;
        }
      }
    }

    this._HelperService.Update_CurrentFilterSnap(
      event,
      Type,
      this.TopUpHistoryList_Config


    );

    this.TopUpHistoryList_Config = this._DataHelperService.List_Operations(
      this.TopUpHistoryList_Config,
      event,
      Type
    );

    if (
      (this.TopUpHistoryList_Config.RefreshData == true)
      && this._HelperService.DataReloadEligibility(Type)
    ) {
      this.TopUpHistoryList_GetData();
    }

  }
  TopUpHistoryList_GetData() {
    var TConfig = this._DataHelperService.List_GetData(
      this.TopUpHistoryList_Config
    );
    this.TopUpHistoryList_Config = TConfig;
  }
  TopUpHistoryList_RowSelected(ReferenceData) {
    this._HelperService.SaveStorage(
      this._HelperService.AppConfig.Storage.ActiveStore,
      {
        ReferenceKey: ReferenceData.ReferenceKey,
        ReferenceId: ReferenceData.ReferenceId,
        DisplayName: ReferenceData.DisplayName,
        AccountTypeCode: this._HelperService.AppConfig.AccountType.Store,
      }
    );

    this._HelperService.AppConfig.ActiveReferenceKey =
      ReferenceData.ReferenceKey;
    this._HelperService.AppConfig.ActiveReferenceId = ReferenceData.ReferenceId;




  }
  ApplyFilters(event: any, Type: any, ButtonType: any): void {
    this._HelperService.MakeFilterSnapPermanent();
    this.TopUpHistoryList_Setup();

    if (ButtonType == 'Sort') {
      $("#TopUpHistoryList_sdropdown").dropdown('toggle');
    } else if (ButtonType == 'Other') {
      $("#TopUpHistoryList_fdropdown").dropdown('toggle');
    }

    this.ResetFilterUI(); this._HelperService.StopClickPropogation();
  }

  ResetFilters(event: any, Type: any): void {
    this._HelperService.ResetFilterSnap();
    this._FilterHelperService.SetMerchantConfig(this.TopUpHistoryList_Config);

    this.TopUpHistoryList_GetData();

    this.ResetFilterUI(); this._HelperService.StopClickPropogation();
  }

  //#endregion

  ResetFilterUI(): void {
    this.ResetFilterControls = false;
    this._ChangeDetectorRef.detectChanges();
    this.ResetFilterControls = true;
    this._ChangeDetectorRef.detectChanges();
  }

  //#endregion
  ViewPlans() {
    this.GetSubscription();
    var Ref = this.generateRandomNumber();
    this._SubscriptionTransactionReference = 'Sc' + '_' + this._HelperService.UserAccount.AccountId + '_' + Ref;

  }

  public _AccountSubscription = {

    Data: []
  }

  public GetSubscription() {
    this._HelperService.IsFormProcessing = true;
    var pData = {
      Task: 'getsubscriptions',
      AccountKey: this._HelperService.AppConfig.ActiveOwnerKey,
      AccountId: this._HelperService.AppConfig.ActiveOwnerId,
      AccountTypeCode: this._HelperService.AppConfig.ActiveOwnerAccountCode,
      Source: this._HelperService.AppConfig.TransactionSource.Merchant
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.AccountSubscription, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._AccountSubscription = _Response.Result;
          this.GetAccountSubscription();

          for (let index = 0; index < this._AccountSubscription.Data.length; index++) {
            const element: any = this._AccountSubscription.Data[index];
            if (element.ReferenceId == 1) {
              this.FreePlan = element
            }
            else if (element.ReferenceId == 2) {
              this.BasicPlan = element
              // document.getElementById("BasicPlanPolicy").innerHTML = element.Policy

            }
            else {
              this.PremiumPlan = element

            }
            for (let index = 0; index < element.Features.length; index++) {
              const features = element.Features[index];

            }


          }
          // this._AccountBalance.Balance = _Response.Result.Balance / 100;
          // this._AccountBalance.Credit = _Response.Result.Credit / 100;
          // this._AccountBalance.Debit = _Response.Result.Debit / 100;
        } else {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      }
    );
  }
  public _GetAccountSubscription = {
    EndDate: null,
    Data: []
  }

  public GetAccountSubscription() {
    this._HelperService.IsFormProcessing = true;
    var pData = {
      Task: 'getaccountsubscriptions',
      AccountKey: this._HelperService.AppConfig.ActiveOwnerKey,
      AccountId: this._HelperService.AppConfig.ActiveOwnerId,
      Offset: 0,
      Limit: 3,
      // Source: this._HelperService.AppConfig.TransactionSource.Merchant
    };
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.AccountSubscription, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._GetAccountSubscription = _Response.Result;
          for (let index = 0; index < this._GetAccountSubscription.Data.length; index++) {
            const element: any = this._GetAccountSubscription.Data[index];

            if (this._GetAccountSubscription.Data[0]) {
              this.CurrentPlan = this._GetAccountSubscription.Data[0]


            }

            // for (let index = 0; index < this._GetAccountSubscription.Data.length; index++) {
            //   const element = this._GetAccountSubscription.Data[index];


            // }


            for (let index = 0; index < element.Features.length; index++) {
              const features = element.Features[index];

            }


          }
          // this._AccountBalance.Balance = _Response.Result.Balance / 100;
          // this._AccountBalance.Credit = _Response.Result.Credit / 100;
          // this._AccountBalance.Debit = _Response.Result.Debit / 100;
        } else {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      }
    );
  }

  NavigateToDashboard() {
    // console.log('In Dash');
    if (this.LoginCredentials.AuthToken != null) {
      // console.log('Inner Dash');

      this._HelperService.IsFormProcessing = true;
      var pData = {
        Task: 'login',
        UserName: this.LoginCredentials.UserName,
        Password: this.LoginCredentials.AuthToken,
        PlatformCode: 'web',
      };
      let _OResponse: Observable<OResponse>;
      _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, pData);
      _OResponse.subscribe(
        _Response => {
          this._HelperService.IsFormProcessing = false;
          if (_Response.Status == this._HelperService.StatusSuccess) {
            this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.Account, _Response.Result);
            var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.OReqH);
            _StorageReqH.hcuak = _Response.Result['AccessKey'];
            _StorageReqH.hcupk = btoa(_Response.Result['PublicKey']);
            this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.OReqH, _StorageReqH);
            // this._HelperService.NotifySuccess(_Response.Message);

            if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Merchant) {
              window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
            } else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.SubAccount) {
              window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
            }

            else {
              // this._HelperService.NotifyError('Invalid account. Please contact Support');

              this._Router.navigate([
                this._HelperService.AppConfig.Pages.System.Login,

              ]);

            }
          }
          else {
            // this._HelperService.NotifyError(_Response.Message);

            this._Router.navigate([
              this._HelperService.AppConfig.Pages.System.Login,

            ]);
          }
        },
        _Error => {
          this._HelperService.IsFormProcessing = false;
          // this._HelperService.HandleException(_Error);

          this._Router.navigate([
            this._HelperService.AppConfig.Pages.System.Login,

          ]);
        });


    } else {
      this._HelperService.NotifyError('Try Again');
    }


  }


}
