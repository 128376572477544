import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataHelperService, HelperService, OResponse, OSelect } from '../../../service/service';
import { Address } from "ngx-google-places-autocomplete/objects/address";
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'registerstore',
  templateUrl: './registerstore.component.html',
})

export class RegisterStoreComponent implements OnInit {
  @ViewChild('ngOtpInput') ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px',
      'margin-right': '8px'
    }
  };

  otpValue: number;
  OtpLength: boolean = false;
  onOtpChange(otp) {
    this.otpValue = otp;

    if (this.otpValue['length'] == 4) {
      this.OtpLength = true;

    }
    else {
      this.OtpLength = false;

    }
  }
  Otp: string;
  Form_AddUser_Processing = false;
  ShowCategorySelector: boolean = true;
  constructor(
    public _FormBuilder: FormBuilder,
    public _TranslateService: TranslateService,
    public _Router: Router,
    public _HelperService: HelperService,
    public _DataHelperService: DataHelperService,
    public _ChangeDetectorRef: ChangeDetectorRef
  ) {
    this.inputmobilenumber = false;
    this.OldMobileNumer = false

  }
  Response: any = {
    Reference: null
  }
  tesvalue: boolean = false;
  ngOnInit() {


    this.Form_AddUser_Load();
    this.GetBusinessCategories();
    // this.TUTr_Filter_Banks_Load();
    // this.TUTr_Filter_Providers_Load();

    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)
    this.resendbutton = true;
    this.timerdiv = false;
    this.inputmobilenumber = false;

  }

  ResetFilterUI() {
    this.resendbutton = true;
    this._ChangeDetectorRef.detectChanges();
    this.resendbutton = false;
    this._ChangeDetectorRef.detectChanges();
  }
  myFunction() {
    document.getElementById("send").style.display = "none";
    document.getElementById("resend").style.display = "block";
  }
  public ChangeNumberV:boolean = false;
  ChangeNumber(){
  
    this._ChangeDetectorRef.detectChanges();
    this.inputmobilenumber = false;
    this.resendbutton  = true;
    this.tesvalue = true;
    this.resendvalue = true;
    this._ChangeDetectorRef.detectChanges();
    this.timerdiv = true
    clearInterval(this.timerleft);

  }

  public timerdiv = false;
  Timer() {
    var timeleft = 60;
    this.timerdiv = true;
    this.timerleft = setInterval(() => {
      if (timeleft <= 0) {
        clearInterval(this.timerleft);
        this.timerdiv = false;
        this.resendbutton = false;
        this.tesvalue = false;

      } else {
        document.getElementById("countdown").innerHTML = timeleft + " seconds";
      }
      timeleft -= 1;
    }, 500);

  }
  public timerleft:any =null 
  ResendTimer() {
    var timeleft = 60;
    this.timerdiv = true;
    this.timerleft = setInterval(() => {
      if (timeleft <= 0) {
        clearInterval(this.timerleft);
        this.timerdiv = false;
        this.resendvalue = true;
     

      } else {
        document.getElementById("countdown").innerHTML = timeleft + " seconds";
      }
      timeleft -= 1;
    }, 500);

  }
  changeISD(e) {
    this.Form_AddUser.get('Isd').setValue(e.target.value, {
      onlySelf: true
    })
    this.CountryIsd = e.target.value

   
  }

  ISD: any = ['234', '233']
  _CurrentAddress: any = {};
  Form_AddUser: FormGroup;
  Form_AddUser_Load() {
    this.Form_AddUser = this._FormBuilder.group({
      OperationType: 'new',
      Task: 'onboardmerchant_st5',
      AccountOperationTypeCode: this._HelperService.AppConfig.AccountOperationType.Offline,
      Name: [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(256)])],
      MobileNumber: [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(14)])],
      Isd: '234',
      Host: null

    });
  }
  Form_AddUser_Process(_FormValue: any) {

    var Request = this.CreateRequestJson();
    this.Form_AddUser_Processing = true;
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, Request);
    _OResponse.subscribe(
      _Response => {
        this.Form_AddUser_Processing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {

          this._Router.navigate([
            this._HelperService.AppConfig.Pages.System.BLocation,

          ]);
        }
        else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this.Form_AddUser_Processing = false;
        this._HelperService.HandleException(_Error);
      });
  }
  Form_AddUser_Address: string = null;
  Form_AddUser_Latitude: number = 0;
  Form_AddUser_Longitude: number = 0;
  Form_AddUser_PlaceMarkerClick(event) {
    this.Form_AddUser_Latitude = event.coords.lat;
    this.Form_AddUser_Longitude = event.coords.lng;
  }
  public Form_AddUser_AddressChange(address: Address) {
    // this.Form_AddUser_Latitude = address.geometry.location.lat();
    // this.Form_AddUser_Longitude = address.geometry.location.lng();
    // this.Form_AddUser_Address = address.formatted_address;

    this.Form_AddUser_Latitude = address.geometry.location.lat();
    this.Form_AddUser_Longitude = address.geometry.location.lng();

    this.Form_AddUser_Address = address.formatted_address;
    this.Form_AddUser.controls['Address'].patchValue(address.formatted_address);
    this._CurrentAddress = this._HelperService.GoogleAddressArrayToJson(address.address_components);
    this.Form_AddUser.controls['CityName'].patchValue(this._CurrentAddress.sublocality_level_2);
    this.Form_AddUser.controls['StateName'].patchValue(this._CurrentAddress.sublocality_level_1);
    this.Form_AddUser.controls['CountryName'].patchValue(this._CurrentAddress.country);
    this.Form_AddUser.controls['Latitude'].patchValue(this.Form_AddUser_Latitude);
    this.Form_AddUser.controls['Longitude'].patchValue(this.Form_AddUser_Longitude);

    if (this._CurrentAddress.country = 'Nigeria') {
      this._HelperService.AppConfig.CurrencySymbolCustom = "&#8358;";

    }
    else {
      this._HelperService.AppConfig.CurrencySymbolCustom = "&#8373;";

    }
  }

  CreateRequestJson(): void {
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)

    var AccountDetail = this._HelperService.OnBoardMerchant
    AccountDetail.Task = "onboardmerchant_st5",
      AccountDetail.MobileNumber = this.Form_AddUser.controls['MobileNumber'].value,
      AccountDetail.Name = this.Form_AddUser.controls['Name'].value,
      AccountDetail.Reference = this.Response.Result.Reference,
      AccountDetail.Otp = this.otpValue,
      AccountDetail.Source = 'Merchant',
      AccountDetail.Host = this._HelperService.AppConfig.Host

    if (this.SelectedBusinessCategories != undefined && this.SelectedBusinessCategories.length > 0) {
      AccountDetail.Categories = []
      this.SelectedBusinessCategories.forEach(element => {
        AccountDetail.Categories.push(element

        )
      });
    }
   
    this._HelperService.SaveStorage(
      this._HelperService.AppConfig.Storage.BusinessData, cloneDeep(AccountDetail)

    );

    return AccountDetail;


  }

  CreateRequestOTPJson(): void {
    //  AccountDetail.Mobile = this.Form_AddUser.controls['Mobile'].value,
  }

  public BusinessCategories = [];
  public S2BusinessCategories = [];
  public CategoriesB: boolean = false

  GetBusinessCategories() {

    this._HelperService.ToggleField = true;
    var PData =
    {
      Task: this._HelperService.AppConfig.Api.Core.getcategories,
      SearchCondition: this._HelperService.GetSearchConditionStrict('', 'StatusCode', this._HelperService.AppConfig.DataType.Text, 'default.active', '='),
      SortExpression: 'Name asc',
      Offset: 0,
      Limit: 1000,
    }
    PData.SearchCondition = this._HelperService.GetSearchConditionStrict(
      PData.SearchCondition,
      "TypeCode",
      this._HelperService.AppConfig.DataType.Text,
      this._HelperService.AppConfig.HelperTypes.MerchantCategories,
      "="
    );
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Op, PData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          if (_Response.Result.Data != undefined) {
            this.BusinessCategories = _Response.Result.Data;

            this.ShowCategorySelector = false;
            this._ChangeDetectorRef.detectChanges();
            for (let index = 0; index < this.BusinessCategories.length; index++) {
              const element = this.BusinessCategories[index];
              this.S2BusinessCategories.push(
                {
                  id: element.ReferenceId,
                  key: element.ReferenceKey,
                  text: element.Name
                }
              );
            }
            this.ShowCategorySelector = true;
            this._ChangeDetectorRef.detectChanges();

            this._HelperService.ToggleField = false;

          }
        }
        else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
        this._HelperService.ToggleField = false;

      });
  }
  public SelectedBusinessCategories = [];
  public TESARRAY = []
  CategoriesSelected(Items) {
    this.TESARRAY = Items.data
    console.log(this.TESARRAY)

   
      if(this.TESARRAY.length == 0){
        this.CategoriesB = false
        console.log("rr2", this.CategoriesB)

      }
      else{
        this.CategoriesB = true
        console.log("rr", this.CategoriesB)

      }

      



    if (Items != undefined && Items.value != undefined && Items.value.length > 0) {
      this.SelectedBusinessCategories = Items.value;

      // if (this.SelectedBusinessCategories.length != 0) {
      //   this.CategoriesB = true

      // }
      // else {
      //   this.CategoriesB = false

      // }

    }
    else {
      this.SelectedBusinessCategories = [];
    }
console.log("rr", this.CategoriesB)

  }
  SaveMerchantBusinessCategory(item) {
    if (item != '0') {
      var Setup =
      {
        Task: this._HelperService.AppConfig.Api.Core.SaveUserParameter,
        TypeCode: this._HelperService.AppConfig.HelperTypes.MerchantCategories,
        // UserAccountKey: this._UserAccount.ReferenceKey,
        CommonKey: item,
        StatusCode: 'default.active'
      };
      let _OResponse: Observable<OResponse>;
      _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V2.System, Setup);
      _OResponse.subscribe(
        _Response => {
          if (_Response.Status == this._HelperService.StatusSuccess) {
            this._HelperService.NotifySuccess('Business category assigned to merchant');
            // this.BusinessCategories = [];
            // this.GetMerchantBusinessCategories();
          }
          else {
            this._HelperService.NotifyError(_Response.Message);
          }
        },
        _Error => {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.HandleException(_Error);
        });
    }
  }


  public TUTr_Filter_Bank_Option: Select2Options;
  TUTr_Filter_Banks_Load() {
    var PlaceHolder = this._HelperService.AppConfig.CommonResource.Select;
    var _Select: OSelect = {
      Task: this._HelperService.AppConfig.Api.Core.GetUserAccountsLite,
      Location: this._HelperService.AppConfig.NetworkLocation.V2.System,
      SearchCondition: "",
      SortCondition: [],
      Fields: [
        {
          SystemName: "ReferenceKey",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: true,
          Text: false,
        },
        {
          SystemName: "DisplayName",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: false,
          Text: true
        },
        {
          SystemName: "AccountTypeCode",
          Type: this._HelperService.AppConfig.DataType.Text,
          SearchCondition: "=",
          SearchValue: this._HelperService.AppConfig.AccountType.Acquirer
        }]
    };
    var _Transport = this._DataHelperService.S2_BuildList(_Select) as any;
    this.TUTr_Filter_Bank_Option = {
      placeholder: 'Select Bank (Acquirer)',
      ajax: _Transport,
      multiple: false,
      allowClear: true,
    };
  }
  TUTr_Filter_Banks_Change(event: any) {
    this.Form_AddUser.patchValue(
      {
        AcquirerReferenceId: event.data[0].ReferenceId,
        AcquirerReferenceKey: event.data[0].ReferenceKey,
      });
  }
  public TUTr_Filter_Provider_Option: Select2Options;
  TUTr_Filter_Providers_Load() {
    var PlaceHolder = this._HelperService.AppConfig.CommonResource.Select;
    var _Select: OSelect = {
      Task: this._HelperService.AppConfig.Api.Core.GetUserAccountsLite,
      Location: this._HelperService.AppConfig.NetworkLocation.V2.System,
      SearchCondition: "",
      SortCondition: [],
      Fields: [
        {
          SystemName: "ReferenceKey",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: true,
          Text: false,
        },
        {
          SystemName: "DisplayName",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: false,
          Text: true
        },
        {
          SystemName: "AccountTypeCode",
          Type: this._HelperService.AppConfig.DataType.Text,
          SearchCondition: "=",
          SearchValue: this._HelperService.AppConfig.AccountType.PosAccount
        }]
    };


    var _Transport = this._DataHelperService.S2_BuildList(_Select) as any;
    this.TUTr_Filter_Provider_Option = {
      placeholder: 'Select  Provider (PTSP)',
      ajax: _Transport,
      multiple: false,
      allowClear: true,
    };
  }
  TUTr_Filter_Providers_Change(event: any) {
    this.Form_AddUser.patchValue(
      {
        ProviderReferenceId: event.data[0].ReferenceId,
        ProviderReferenceKey: event.data[0].ReferenceKey,
      });
  }

  otpreceived: boolean = true;
  public resendbutton: boolean = true;

  Addbusinessdetails() {
    this._Router.navigate([
      this._HelperService.AppConfig.Pages.System.BLocation,

    ]);
  }

  IsdValue(value) {
  

    if (value == '+234') {

      this.Form_AddUser.patchValue(
        {
          Isd: value

        });

    }
    else {

      this.Form_AddUser.patchValue(
        {
          Isd: value

        });

    }
  }

  OtpMobileNumber = null
  public OldMobileNumer: any = false
  CountryIsd = '234'
  public inputmobilenumber: boolean;
  KeyUP() {

    if (this.OtpMobileNumber['length'] >= 10) {
      this.tesvalue = true;
    } else {
      this.tesvalue = false;

    }

    if (this.OtpMobileNumber == this.OldMobileNumber) {
      this.OldMobileNumer = true;

    }
    else {

      this.OldMobileNumer = false;
    }

  }
  public OldMobileNumber: number = null
  public VerifyCode(): void {
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)
    this._HelperService.IsFormProcessing = true;
    this._HelperService.AppConfig.ShowHeader = true;
    this.tesvalue = false;
    this.inputmobilenumber = true;

    var pData = {
      Task: "onboardmerchant_st4",
      Reference: null,
      MobileNumber: this.OtpMobileNumber,
      Isd: this.CountryIsd,
      Source: 'Merchant'
    };

    if (pData.MobileNumber != null && pData.MobileNumber != undefined) {
      this.OldMobileNumber = this.OtpMobileNumber
      pData.Reference = this.Response.Result.Reference;
      pData.MobileNumber = this.Form_AddUser.controls['MobileNumber'].value;
      pData.Isd = this.Form_AddUser.controls['Isd'].value;
      let _OResponse: Observable<OResponse>;

      _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, pData);
      _OResponse.subscribe(
        _Response => {
          if (_Response.Status == this._HelperService.StatusSuccess) {
            this.Timer();
            this.otpreceived = false;
            this.ChangeNumberV = true;

          } 
          else if (_Response.ResponseCode == 'CA1420') {
            this._HelperService.IsFormProcessing = false;
            this._HelperService.NotifyError(_Response.Message);
            this.ChangeNumberV = true;
            return;
        }
          
          else {
            this._HelperService.IsFormProcessing = false;
            this._HelperService.NotifyError(_Response.Message);
            // this.Timer();

          }
        },
        _Error => {
          this._HelperService.HandleException(_Error);

        }
      );
    }
    else {
      this._HelperService.NotifyError('Please Enter Mobile and Isd Number')
    }


  }
  public resendvalue: boolean = true;

  public ResendOtp(): void {
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)
    this._HelperService.IsFormProcessing = true;
    this.resendvalue = false;
    this.inputmobilenumber = true;

    this._HelperService.AppConfig.ShowHeader = true;
    var pData = {
      Task: "onboardmerchant_st4",
      Reference: this.Response.Result.Reference,
      MobileNumber: this.OtpMobileNumber,
      Isd: this.CountryIsd,
      Source: 'Merchant',
      // Token:this.ngOtpInputRef.getValue(),
    };
    pData.Reference = this.Response.Result.Reference;
    pData.MobileNumber = this.Form_AddUser.controls['MobileNumber'].value;
    pData.Isd = this.Form_AddUser.controls['Isd'].value;
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._HelperService.IsFormProcessing = false;
          this.ResendTimer();
          // this.resendvalue = true;


        }
        else if (_Response.ResponseCode == 'CA1420') {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
          this.ChangeNumberV = true;
          return;
      }
       else {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
          // this.ResendTimer();

        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
        // this.ResendTimer();

      }
    );
  }

}
