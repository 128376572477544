import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataHelperService, HelperService, OResponse, OSelect } from '../../../service/service';
import { Address } from "ngx-google-places-autocomplete/objects/address";
import * as cloneDeep from 'lodash/cloneDeep';
import { Select2OptionData } from 'ng2-select2';

@Component({
  selector: 'app-businesslocation',
  templateUrl: './businesslocation.component.html',
  styleUrls: ['./businesslocation.component.css']
})
// export class BusinesslocationComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }



export class BusinesslocationComponent implements OnInit {
  Form_AddUser_Processing = false;
  ShowCategorySelector: boolean = true;
  constructor(
    public _FormBuilder: FormBuilder,
    public _TranslateService: TranslateService,
    public _Router: Router,
    public _HelperService: HelperService,
    public _DataHelperService: DataHelperService,
    public _ChangeDetectorRef: ChangeDetectorRef
  ) {

  }

  Response: any = {}
  LoginCredentials: any = {
    UserName: null,
    Password: null,
    PlatformCode: 'web',
  }
  ngOnInit() {
    this.data.CountryId = 1;
    this.data.CountryCode = "nigeria";
    this.data.CountryName = "Nigeria";
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.BusinessData)
    // console.log("test", this.Response)
    // this.Form_AddUser_Load();
    // this.GetStateCategories();
  }


  // _CurrentAddress: any = {};
  // Form_AddUser: FormGroup;
  // Form_AddUser_Load() {
  //   this.Form_AddUser = this._FormBuilder.group({
  //     OperationType: 'new',
  //     Task: this._HelperService.AppConfig.Api.Core.onboardmerchant,
  //     AccountOperationTypeCode: this._HelperService.AppConfig.AccountOperationType.Offline,

  //     Address: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(256)])],
  //     StateName: null,
  //     CityName: null,
  //     CountryName: null,
  //     Latitude: null,
  //     Longitude: null,
  //     ReferralCode: null,
  //     Host: null,
  //     StatusCode: this._HelperService.AppConfig.Status.Active,
  //   });
  // }
  Form_AddUser_Process() {
    if (this.data.Latitude == 0 || this.data.Longitude == 0) {
      this._HelperService.NotifyError('Mark your business location on map to continue');
    }
    else {
      if (this._HelperService.AppConfig.Host == 'merchantrewards.interswitch.com') {
        this._HelperService.IsFormProcessing = true;
        var Request = this.CreateRequestJsonInterswitch();
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, Request);
        _OResponse.subscribe(
          _Response => {
            this._HelperService.IsFormProcessing = false;

            if (_Response.Status == this._HelperService.StatusSuccess) {
              this.LoginCredentials = _Response.Result
              setTimeout(() => {
                this.NavigateToDashboard();
              }, 500);
              this._HelperService.NotifySuccess('Welcome! Your merchant registered successfully');
            } else {
              this._HelperService.NotifyError(_Response.Message);
            }
          },
          _Error => {
            this._HelperService.IsFormProcessing = false;
            this._HelperService.HandleException(_Error);
          }
        );

      }
      else if (this._HelperService.AppConfig.Host == 'interswitch.thankucash.com') {
        this._HelperService.IsFormProcessing = true;
        var Request = this.CreateRequestJsonInterswitch();
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, Request);
        _OResponse.subscribe(
          _Response => {
            this._HelperService.IsFormProcessing = false;
            if (_Response.Status == this._HelperService.StatusSuccess) {
              this.LoginCredentials = _Response.Result
              setTimeout(() => {
                this.NavigateToDashboard();
              }, 500);
              this._HelperService.NotifySuccess('Welcome! Your merchant registered successfully');
            } else {
              this._HelperService.NotifyError(_Response.Message);
            }
          },
          _Error => {
            this._HelperService.IsFormProcessing = false;
            this._HelperService.HandleException(_Error);
          }
        );
      }
      else {
        this._HelperService.IsFormProcessing = true;
        var Request = this.CreateRequestJsonInterswitch();
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, Request);
        _OResponse.subscribe(
          _Response => {
            this._HelperService.IsFormProcessing = false;
            if (_Response.Status == this._HelperService.StatusSuccess) {
              this.LoginCredentials = _Response.Result
              setTimeout(() => {
                this.NavigateToDashboard();
              }, 500);
              this._HelperService.NotifySuccess('Welcome! Your merchant registered successfully');
            } else {
              this._HelperService.NotifyError(_Response.Message);
            }
          },
          _Error => {
            this._HelperService.IsFormProcessing = false;
            this._HelperService.HandleException(_Error);
          }
        );
        // var Request = this.CreateRequestJson();
        // this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.AllSetUpData, cloneDeep(Request));
        // this.Form_AddUser_Processing = true;
        // this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscriptionOnBoard,]);
      }
    }
  }

  NavigateToDashboard() {
    // console.log('In Dash');
    if (this.LoginCredentials.AuthToken != null) {
      // console.log('Inner Dash');

      this._HelperService.IsFormProcessing = true;
      var pData = {
        Task: 'login',
        UserName: this.LoginCredentials.UserName,
        Password: this.LoginCredentials.AuthToken,
        PlatformCode: 'web',
      };
      let _OResponse: Observable<OResponse>;
      _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V1.System, pData);
      _OResponse.subscribe(
        _Response => {
          this._HelperService.IsFormProcessing = false;
          if (_Response.Status == this._HelperService.StatusSuccess) {
            this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.Account, _Response.Result);
            var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.OReqH);
            _StorageReqH.hcuak = _Response.Result['AccessKey'];
            _StorageReqH.hcupk = btoa(_Response.Result['PublicKey']);
            this._HelperService.SaveStorage(this._HelperService.AppConfig.Storage.OReqH, _StorageReqH);
            // this._HelperService.NotifySuccess(_Response.Message);

            if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.Merchant) {
              window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
            } else if (_Response.Result.UserAccount.AccountTypeCode == this._HelperService.AppConfig.AccountType.SubAccount) {
              window.location.href = this._HelperService.AppConfig.Pages.ThankUCash.Dashboard.Merchant;
            }

            else {
              // this._HelperService.NotifyError('Invalid account. Please contact Support');

              this._Router.navigate([
                this._HelperService.AppConfig.Pages.System.Login,

              ]);

            }
          }
          else {
            // this._HelperService.NotifyError(_Response.Message);

            this._Router.navigate([
              this._HelperService.AppConfig.Pages.System.Login,

            ]);
          }
        },
        _Error => {
          this._HelperService.IsFormProcessing = false;
          // this._HelperService.HandleException(_Error);

          this._Router.navigate([
            this._HelperService.AppConfig.Pages.System.Login,

          ]);
        });


    } else {
      this._HelperService.NotifyError('Try Again');
    }


  }

  // Form_AddUser_Address: string = null;
  // Form_AddUser_Latitude: number = 0;
  // Form_AddUser_Longitude: number = 0;
  // Form_AddUser_PlaceMarkerClick(event) {
  //   this.Form_AddUser_Latitude = event.coords.lat;
  //   this.Form_AddUser_Longitude = event.coords.lng;
  // }
  // public Form_AddUser_AddressChange(address: Address) {
  //   // this.Form_AddUser_Latitude = address.geometry.location.lat();
  //   // this.Form_AddUser_Longitude = address.geometry.location.lng();
  //   // this.Form_AddUser_Address = address.formatted_address;

  //   this.Form_AddUser_Latitude = address.geometry.location.lat();
  //   this.Form_AddUser_Longitude = address.geometry.location.lng();

  //   this.Form_AddUser_Address = address.formatted_address;
  //   this.Form_AddUser.controls['Address'].patchValue(address.formatted_address);
  //   this._CurrentAddress = this._HelperService.GoogleAddressArrayToJson(address.address_components);
  //   this.Form_AddUser.controls['CityName'].patchValue(this._CurrentAddress.sublocality_level_2);
  //   this.Form_AddUser.controls['StateName'].patchValue(this._CurrentAddress.sublocality_level_1);
  //   this.Form_AddUser.controls['CountryName'].patchValue(this._CurrentAddress.country);
  //   this.Form_AddUser.controls['Latitude'].patchValue(this.Form_AddUser_Latitude);
  //   this.Form_AddUser.controls['Longitude'].patchValue(this.Form_AddUser_Longitude);


  //   if (this._CurrentAddress.country = 'Nigeria') {
  //     this._HelperService.AppConfig.CurrencySymbolCustom = "&#8358;";

  //   }
  //   else {
  //     this._HelperService.AppConfig.CurrencySymbolCustom = "&#8373;";

  //   }
  // }
  generateRandomNumber() {
    var TrRef = Math.floor(100000 + Math.random() * (999999 + 1 - 100000));
    return TrRef;
  }

  CreateRequestJson(): void {
    var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.BusinessData);
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)
    var AccountDetail = this._HelperService.OnBoardMerchant
    AccountDetail.Task = 'onboardmerchant_st6',
      AccountDetail.Name = _StorageReqH.Name,
      AccountDetail.ReferralCode = this.data.ReferralCode,
      AccountDetail.StatusCode = this._HelperService.AppConfig.Status.Active,
      AccountDetail.Source = 'Merchant',
      AccountDetail.Reference = this.Response.Result.Reference,
      AccountDetail.Host = this._HelperService.AppConfig.Host,
      AccountDetail.Address = this.data,
      AccountDetail.Categories = [];
    AccountDetail.Categories = _StorageReqH.Categories;
    // console.log("AccountDetail.Categories", AccountDetail.Categories);
    return AccountDetail;
  }
  public _SubscriptionTransactionReference = null;
  CreateRequestJsonInterswitch(): void {
    var Ref = this.generateRandomNumber();
    this._SubscriptionTransactionReference = 'freeSubscription' + '_' + Ref;
    var _StorageReqH = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.BusinessData);
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)
    var AccountDetail = this._HelperService.OnBoardMerchant
    AccountDetail.Task = 'onboardmerchant_st6',
      AccountDetail.Name = _StorageReqH.Name,
      AccountDetail.DisplayName = _StorageReqH.Name,

      AccountDetail.ReferralCode = this.data.ReferralCode,
      AccountDetail.StatusCode = this._HelperService.AppConfig.Status.Active,
      AccountDetail.Source = 'Merchant',
      AccountDetail.Reference = this.Response.Result.Reference,
      AccountDetail.Host = this._HelperService.AppConfig.Host
    AccountDetail.SubscriptionId = "22"
    AccountDetail.SubscriptionKey = "thankucahsisw",
      AccountDetail.PaymentReference = this._SubscriptionTransactionReference,

      AccountDetail.Address = this.data,
      AccountDetail.Categories = [];
    AccountDetail.Categories = _StorageReqH.Categories;
    return AccountDetail;
  }





  showsubscription() {
    this._Router.navigate([this._HelperService.AppConfig.Pages.System.NoAccess,]);
  }


  //state
  // public StateCategories = [];
  // public S2StateCategories = [];
  // public ShowstateSelector: boolean = true;
  // public ShowcitySelector: boolean = true;
  // GetStateCategories() {

  //   this._HelperService.ToggleField = true;
  //   var PData =
  //   {
  //     Task: this._HelperService.AppConfig.Api.Core.getstates,
  //     ReferenceKey: this._HelperService.UserCountrykey,
  //     ReferenceId: this._HelperService.UserCountryId,
  //     //SearchCondition: this._HelperService.GetSearchConditionStrict('', 'StatusCode', this._HelperService.AppConfig.DataType.Text, 'default.active', '='),
  //     // SortExpression: 'Name asc',
  //     Offset: 0,
  //     Limit: 1000,
  //   }
  //   // PData.SearchCondition = this._HelperService.GetSearchConditionStrict(
  //   //     PData.SearchCondition,
  //   //     "TypeCode",
  //   //     this._HelperService.AppConfig.DataType.Text,
  //   //     this._HelperService.AppConfig.HelperTypes.MerchantCategories,
  //   //     "="
  //   // );
  //   let _OResponse: Observable<OResponse>;
  //   _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.State, PData);
  //   _OResponse.subscribe(
  //     _Response => {
  //       if (_Response.Status == this._HelperService.StatusSuccess) {
  //         if (_Response.Result.Data != undefined) {
  //           this.StateCategories = _Response.Result.Data;

  //           this.ShowstateSelector = false;
  //           this._ChangeDetectorRef.detectChanges();
  //           for (let index = 0; index < this.StateCategories.length; index++) {
  //             const element = this.StateCategories[index];
  //             this.S2StateCategories.push(
  //               {
  //                 id: element.ReferenceId,
  //                 key: element.ReferenceKey,
  //                 text: element.Name
  //               }
  //             );
  //           }
  //           this.ShowstateSelector = true;
  //           this._ChangeDetectorRef.detectChanges();

  //           this._HelperService.ToggleField = false;

  //         }
  //       }
  //       else {
  //         this._HelperService.NotifyError(_Response.Message);
  //       }
  //     },
  //     _Error => {
  //       this._HelperService.IsFormProcessing = false;
  //       this._HelperService.HandleException(_Error);
  //       this._HelperService.ToggleField = false;

  //     });
  // }
  // public SelectedStateCategories = [];
  // public selectedstate: any;
  // statekey: any; stateid: any; statename: any;
  // StateSelected(Items) {
  //   if (Items != undefined && Items.value != undefined && Items.value.length > 0) {
  //     this.SelectedStateCategories = Items.value;
  //     this.statekey = Items.data[0].key;
  //     this.stateid = Items.data[0].id;
  //     this.statename = Items.data[0].text;
  //     this.selectedstate = true;
  //     this.GetCityCategories()
  //     console.log(" this.SelectedStateCategories", this.SelectedStateCategories, Items.data[0], this.statekey, this.stateid)
  //   }
  //   else {
  //     this.SelectedStateCategories = [];
  //   }
  // }
  //City
  // public CityCategories = [];
  // public S2CityCategories = [];
  // GetCityCategories() {
  //   this._HelperService.ToggleField = true;
  //   var PData =
  //   {
  //     Task: this._HelperService.AppConfig.Api.Core.getcities,
  //     ReferenceKey: this.statekey,
  //     ReferenceId: this.stateid,
  //     //SearchCondition: this._HelperService.GetSearchConditionStrict('', 'StatusCode', this._HelperService.AppConfig.DataType.Text, 'default.active', '='),
  //     // SortExpression: 'Name asc',
  //     Offset: 0,
  //     Limit: 1000,
  //   }
  //   // PData.SearchCondition = this._HelperService.GetSearchConditionStrict(
  //   //     PData.SearchCondition,
  //   //     "TypeCode",
  //   //     this._HelperService.AppConfig.DataType.Text,
  //   //     this._HelperService.AppConfig.HelperTypes.MerchantCategories,
  //   //     "="
  //   // );
  //   let _OResponse: Observable<OResponse>;
  //   _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.City, PData);
  //   _OResponse.subscribe(
  //     _Response => {
  //       if (_Response.Status == this._HelperService.StatusSuccess) {
  //         if (_Response.Result.Data != undefined) {
  //           this.CityCategories = _Response.Result.Data;
  //           console.log(this.CityCategories)
  //           this.ShowcitySelector = false;
  //           this._ChangeDetectorRef.detectChanges();
  //           for (let index = 0; index < this.CityCategories.length; index++) {
  //             const element = this.CityCategories[index];
  //             this.S2CityCategories.push(
  //               {
  //                 id: element.ReferenceId,
  //                 key: element.ReferenceKey,
  //                 text: element.Name
  //               }
  //             );
  //             console.log(this.S2CityCategories)
  //           }
  //           console.log(this.S2CityCategories)
  //           this.ShowcitySelector = true;
  //           this._ChangeDetectorRef.detectChanges();

  //           this._HelperService.ToggleField = false;

  //         }
  //       }
  //       else {
  //         this._HelperService.NotifyError(_Response.Message);
  //       }
  //     },
  //     _Error => {
  //       this._HelperService.IsFormProcessing = false;
  //       this._HelperService.HandleException(_Error);
  //       this._HelperService.ToggleField = false;

  //     });


  // }
  // public SelectedCityCategories = [];
  // public citykey: any; public cityid: any; cityname: any;
  // CitySelected(Items) {
  //   if (Items != undefined && Items.value != undefined && Items.value.length > 0) {
  //     this.SelectedCityCategories = Items.value;
  //     this.citykey = Items.data[0].key;
  //     this.cityid = Items.data[0].id
  //     this.cityname = Items.data[0].text
  //     console.log(" this.SelectedcityCategories", this.SelectedCityCategories, Items.data[0], this.citykey, this.cityid, this.cityname)
  //   }
  //   else {
  //     this.SelectedCityCategories = [];
  //   }
  // }


  ShowCity = false;
  ShowState = false;
  IsAddressSet = false;
  public data =
    {

      Address: null,

      Latitude: null,
      Longitude: null,

      CityAreaId: null,
      CityAreaCode: null,
      CityAreaName: null,

      CityId: null,
      CityCode: null,
      CityName: null,

      StateId: null,
      StateCode: null,
      StateName: null,

      CountryId: null,
      CountryCode: null,
      CountryName: null,

      PostalCode: null,

      MapAddress: null,

      ReferralCode: null,

    };



  HCXLocManager_OpenUpdateManager_Clear() {
    this.IsAddressSet = false;
    this.data =
    {
      Latitude: 0,
      Longitude: 0,

      Address: null,
      MapAddress: null,

      CountryId: 0,
      CountryCode: null,
      CountryName: null,

      StateId: 0,
      StateName: null,
      StateCode: null,

      CityId: 0,
      CityCode: null,
      CityName: null,

      CityAreaId: 0,
      CityAreaName: null,
      CityAreaCode: null,

      PostalCode: null,
      ReferralCode: null,
    }
  }
  HCXLocManager_AddressChange(address: Address) {
    this.data.Latitude = address.geometry.location.lat();
    this.data.Longitude = address.geometry.location.lng();
    this.data.MapAddress = address.formatted_address;
    this.data.Address = address.formatted_address;
    var tAddress = this._HelperService.GoogleAddressArrayToJson(address.address_components);;
    if (tAddress.postal_code != undefined && tAddress.postal_code != null && tAddress.postal_code != "") {
      this.data.PostalCode = tAddress.postal_code;
    }
    if (tAddress.country != undefined && tAddress.country != null && tAddress.country != "") {
      this.data.CountryName = tAddress.country;
    }
    if (tAddress.administrative_area_level_1 != undefined && tAddress.administrative_area_level_1 != null && tAddress.administrative_area_level_1 != "") {
      this.data.StateName = tAddress.administrative_area_level_1;
    }
    if (tAddress.locality != undefined && tAddress.locality != null && tAddress.locality != "") {
      this.data.CityName = tAddress.locality;
    }
    if (tAddress.administrative_area_level_2 != undefined && tAddress.administrative_area_level_2 != null && tAddress.administrative_area_level_2 != "") {
      this.data.CityAreaName = tAddress.administrative_area_level_2;
    }
    if (tAddress.administrative_area_level_2 != undefined && tAddress.administrative_area_level_2 != null && tAddress.administrative_area_level_2 != "") {
      this.data.CityAreaName = tAddress.administrative_area_level_2;
    }

    if (this.data.CountryName != "Nigeria") {
      this._HelperService.NotifyError('Currently we’re not serving in this area, please add locality within ' + this._HelperService.UserCountrycode);
      this.HCXLocManager_OpenUpdateManager_Clear();
    }
    else {
      this.data.CountryId = 1;
      this.data.CountryCode = "nigeria";
      this.data.CountryName = "Nigeria";
      this.HCXLoc_Manager_GetStates();
    }
  }

  public HCXLocManager_S2States_Data: Array<Select2OptionData>;
  HCXLoc_Manager_GetStates() {
    this._HelperService.IsFormProcessing = true;
    this.ShowState = false;
    var PData =
    {
      Task: this._HelperService.AppConfig.Api.Core.getstates,
      ReferenceKey: this.data.CountryCode,
      ReferenceId: this.data.CountryId,
      Offset: 0,
      Limit: 1000,
    }
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.State, PData);
    _OResponse.subscribe(
      _Response => {
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {
          if (_Response.Result.Data != undefined) {
            var _Data = _Response.Result.Data as any[];
            if (_Data.length > 0) {
              var finalCat = [];
              _Data.forEach(element => {
                var Item = {
                  id: element.ReferenceId,
                  key: element.ReferenceKey,
                  text: element.Name,
                  additional: element,
                };
                if (this.data.StateName != undefined || this.data.StateName != null || this.data.StateName != '') {
                  if (element.Name == this.data.StateName) {
                    this.data.StateId = Item.id;
                    this.data.StateCode = Item.key;
                    this.data.StateName = Item.text;
                    this.ShowCity = false;
                    this.HCXLoc_Manager_City_Load();
                    setTimeout(() => {
                      this.ShowCity = true;
                    }, 500);
                  }
                }
                finalCat.push(Item);
              });
              this.HCXLocManager_S2States_Data = finalCat;
              this.ShowState = true;
              if (this.data.CityName != undefined && this.data.CityName != null && this.data.CityName != "") {
                this.HCXLoc_Manager_GetStateCity(this.data.CityName);
              }
            }
          }
        }
        else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
        this._HelperService.ToggleField = false;

      });
  }
  HCXLoc_Manager_StateSelected(Items) {
    if (Items != undefined && Items.value != undefined && Items.data.length > 0) {
      this.data.StateId = Items.data[0].id;
      this.data.StateCode = Items.data[0].key;
      this.data.StateName = Items.data[0].text;
      this.data.CityId = null;
      this.data.CityCode = null;
      this.data.CityName = null;

      this.data.Address = null;
      this.data.MapAddress = null;
      this.data.Latitude = 0;
      this.data.Longitude = 0;
      this.ShowCity = false;
      this.HCXLoc_Manager_City_Load();
      setTimeout(() => {
        this.ShowCity = true;
      }, 500);
    }
  }
  HCXLoc_Manager_GetStateCity(CityName) {
    this._HelperService.IsFormProcessing = true;
    var PData =
    {
      Task: this._HelperService.AppConfig.Api.Core.getstates,
      ReferenceKey: this.data.CountryCode,
      ReferenceId: this.data.CountryId,
      SearchCondition: this._HelperService.GetSearchConditionStrict('', 'Name', 'text', CityName, '='),
      Offset: 0,
      Limit: 1,
    }
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.State, PData);
    _OResponse.subscribe(
      _Response => {
        this._HelperService.IsFormProcessing = false;
        if (_Response.Status == this._HelperService.StatusSuccess) {
          if (_Response.Result.Data != undefined) {
            var _Result = _Response.Result.Data;
            if (_Result != undefined && _Result != null && _Result.length > 0) {
              var Item = _Result[0];
              this.data.CityId = Item.ReferenceId;
              this.data.CityCode = Item.ReferenceKey;
              this.data.CityName = Item.Name;
              this.ShowCity = false;
              this.HCXLoc_Manager_City_Load();
              setTimeout(() => {
                this.ShowCity = true;
              }, 500);
            }
          }
        }
        else {
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.IsFormProcessing = false;
        this._HelperService.HandleException(_Error);
        this._HelperService.ToggleField = false;

      });
  }

  public HCXLoc_Manager_City_Option: Select2Options;
  HCXLoc_Manager_City_Load() {
    var _Select: OSelect = {
      Task: this._HelperService.AppConfig.Api.Core.getcities,
      Location: this._HelperService.AppConfig.NetworkLocation.V3.City,
      ReferenceId: this.data.StateId,
      ReferenceKey: this.data.StateCode,
      SearchCondition: "",
      SortCondition: [],
      Fields: [
        {
          SystemName: "ReferenceId",
          Type: this._HelperService.AppConfig.DataType.Number,
          Id: true,
          Text: false,
        },
        {
          SystemName: "Name",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: false,
          Text: true,
        },
      ],
    };
    var _Transport = this._DataHelperService.S2_BuildList(_Select) as any;
    if (this.data.CityName != undefined || this.data.CityName != null && this.data.CityName != '') {
      this.HCXLoc_Manager_City_Option = {
        placeholder: this.data.CityName,
        ajax: _Transport,
        multiple: false,
        allowClear: false,
      };
    }
    else {
      this.HCXLoc_Manager_City_Option = {
        placeholder: "Select City",
        ajax: _Transport,
        multiple: false,
        allowClear: false,
      };
    }

  }
  HCXLoc_Manager_City_Change(Items: any) {
    if (Items != undefined && Items.value != undefined && Items.data.length > 0) {
      this.data.CityId = Items.data[0].ReferenceId;
      this.data.CityCode = Items.data[0].ReferenceKey;
      this.data.CityName = Items.data[0].Name;
    }
  }
  HCXLoc_Manager_SetAddress() {
    if (this.data.Address == undefined || this.data.Address == null || this.data.Address == "") {
      this._HelperService.NotifyError("Please enter address")
    }
    else if (this.data.CountryId == undefined || this.data.CountryId == null || this.data.CountryId == 0) {
      this._HelperService.NotifyError("Please select country")
    }
    else if (this.data.StateId == undefined || this.data.StateId == null || this.data.StateId == 0) {
      this._HelperService.NotifyError("Please select state")
    }
    else if (this.data.CityId == undefined || this.data.CityId == null || this.data.CityId == 0) {
      this._HelperService.NotifyError("Please select city")
    }
    else {
      this.Form_AddUser_Process();
      // this.showsubscription();
      this.IsAddressSet = true;
    }
  }
}