
var SystemHelper = (function () {
    "use strict";
    return {
        Notify: (function (Type, Message) {
            Messenger({
                extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
                theme: 'flat',
            }).post({
                message: Message,
                type: Type,
                showCloseButton: true
            });
            return 'done';
        }),
        TriggerClick: (function (Item) {
            $(Item).click();
            return 'done';
        }),
        GetUserTimeZone: (function () {
            return moment.tz.guess();
        }),
        GetDateTime: (function (Date, TimeZone) {
            try {
                if (Date != undefined && Date != null) {
                    var TDate = moment.tz(Date, 'GMT');
                    var FDate = moment.tz(TDate, TimeZone);
                    return FDate;
                }
                else {
                    return new Date();
                }
            } catch (error) {
                return new Date();
            }

        }),
        GetDateTimeGMT: (function (Date, TimeZone) {
            moment.tz.setDefault(TimeZone);
            try {
                if (Date != undefined && Date != null) {
                    var tTime = moment(Date).tz(TimeZone, true);
                    var seconds = Date.second();
                    var minute = Date.minute();
                    var hour = Date.hour();
                    var day = Date.date();
                    var month = Date.month() + 1;
                    var year = Date.year();
                    // moment.tz("2013-08-26 16:55:00", "America/Los_Angeles")
                    var m = moment.tz(year + "-" + month + "-" + day + " " + hour + ":" + minute + ":00", TimeZone);
                    // var m = moment.tz(Date, moment.tz.guess());
                    // m.format();                           // 2013-11-18T11:55:00-05:00
                    // m.tz(TimeZone, true).format()  // 2013-11-18T11:55:00+01:00
                    // var check = moment(Date);
                    var gmtTIime = moment.tz(m, 'GMT');
                    return gmtTIime;
                    // return moment(Date).valueOf();
                    // var TDate = moment.tz(Date, 'GMT');
                    // // var FDate = moment.tz(TDate, TimeZone);
                    // return TDate;
                }
                else {
                    return new Date();
                }
            } catch (error) {
               // console.log(error);
                return new Date();
            }

        }),
        GetTimeS: (function (Date, TimeZone, Format) {

            try {
                if (Date != undefined && Date != null) {
                    var TDate = moment.tz(Date, 'GMT');
                    var FDate = moment.tz(TDate, TimeZone).format(Format);
                    // console.log('GetTimeS ' + Date + ' || ' + TimeZone + ' || ' + Format + ' || GMT : ' + TDate + ' || CONVERTED :' + FDate);
                    return FDate;
                }
                else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        }),
        GetDateS: (function (Date, TimeZone, Format) {
            try {
                if (Date != undefined && Date != null) {
                    var TDate = moment.tz(Date, 'GMT');
                    var FDate = moment.tz(TDate, TimeZone).format(Format);
                    return FDate;
                }
                else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        }),
        GetDateTimeS: (function (Date, TimeZone, Format) {
            try {
                if (Date != undefined && Date != null) {
                    var TDate = moment.tz(Date, 'GMT');
                    var FDate = moment.tz(TDate, TimeZone).format(Format);
                    return FDate;
                }
                else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        }),
        GetPageName: (function () {
            return location.pathname.replace("/system/", "");
        }),
        CheckDateIsAfter: (function (Date, CompareTo) {
            return moment(Date).isAfter(CompareTo);
        }),
        CheckDateIsBefore: (function (Date, CompareTo) {
            return moment(Date).isBefore(CompareTo);
        }),
        GetTimeDifference: (function (Date, CompareTo) {
            var date1 = moment.tz(Date, 'GMT'),
                date2 = moment.tz(CompareTo, 'GMT');
            var Duration = moment.duration(date2.diff(date1));
            var DiffernceO =
            {
                Years: 0,
                Month: 0,
                Weeks: 0,
                Days: 0,
                Hours: 0,
                Minutes: 0,
                Seconds: 0
            }
            DiffernceO.Years = Math.round(Math.abs(Duration.asYears()));
            DiffernceO.Month = Math.round(Math.abs(Duration.asMonths()));
            DiffernceO.Weeks = Math.round(Math.abs(Duration.asWeeks()));
            DiffernceO.Days = Math.round(Math.abs(Duration.asDays()));
            DiffernceO.Hours = Math.round(Math.abs(Duration.asHours()));
            DiffernceO.Minutes = Math.round(Math.abs(Duration.asMinutes()));
            DiffernceO.Seconds = Math.round(Math.abs(Duration.asSeconds()));
            return DiffernceO;
        }),
        GetTimeDifferenceS: (function (Date, CompareTo) {
            var date1 = moment.tz(Date, 'GMT'),
                date2 = moment.tz(CompareTo, 'GMT');
            var Duration = moment.duration(date2.diff(date1));
            var DiffernceO =
            {
                Years: 0,
                Month: 0,
                Weeks: 0,
                Days: 0,
                Hours: 0,
                Minutes: 0,
                Seconds: 0
            }
            DiffernceO.Years = Math.round(Math.abs(Duration.asYears()));
            DiffernceO.Month = Math.round(Math.abs(Duration.asMonths()));
            DiffernceO.Weeks = Math.round(Math.abs(Duration.asWeeks()));
            DiffernceO.Days = Math.round(Math.abs(Duration.asDays()));
            DiffernceO.Hours = Math.round(Math.abs(Duration.asHours()));
            DiffernceO.Minutes = Math.round(Math.abs(Duration.asMinutes()));
            DiffernceO.Seconds = Math.round(Math.abs(Duration.asSeconds()));
            if (DiffernceO.Years > 0) {
                return DiffernceO.Years + ' years';
            }
            else if (DiffernceO.Month > 0) {
                return DiffernceO.Years + ' months';
            }
            else if (DiffernceO.Days > 0) {
                return DiffernceO.Days + ' days';
            }
            else if ((DiffernceO.Hours) > 0) {
                return (DiffernceO.Hours) + ' hours ' + (DiffernceO.Minutes - ((DiffernceO.Hours - 1) * 60)) + ' mins';
            }
            else if (DiffernceO.Minutes > 0) {
                return DiffernceO.Minutes + ' min';
            }
            else {
                return DiffernceO.Seconds + ' sec';
            }
        }),
        GetTimeInterval: (function (Date, CompareTo, TimeZone) {
            var TDate = moment.tz(Date, 'GMT');
            var NDate = moment.tz(TDate, TimeZone);
            var TCompareTo = moment.tz(CompareTo, 'GMT');
            var NCompareToDate = moment.tz(TCompareTo, TimeZone);
            var Duration = moment.duration(NCompareToDate.diff(NDate));
            var DiffernceO =
            {
                Years: 0,
                Month: 0,
                Weeks: 0,
                Days: 0,
                Hours: 0,
                Minutes: 0,
                Seconds: 0
            }
            DiffernceO.Years = Math.round(Math.abs(Duration.asYears()));
            DiffernceO.Month = Math.round(Math.abs(Duration.asMonths()));
            DiffernceO.Weeks = Math.round(Math.abs(Duration.asWeeks()));
            DiffernceO.Days = Math.round(Math.abs(Duration.asDays()));
            DiffernceO.Hours = Math.round(Math.abs(Duration.asHours()));
            DiffernceO.Minutes = Math.round(Math.abs(Duration.asMinutes()));
            DiffernceO.Seconds = Math.round(Math.abs(Duration.asSeconds()));
            if (DiffernceO.Years > 0) {
                var TDate = moment.tz(Date, 'GMT');
                var FDate = moment.tz(TDate, TimeZone).format('DD MMM YYYY h:mm a');
                return FDate;
            }
            else if (DiffernceO.Month > 0) {
                return DiffernceO.Month + ' months';
            }
            else if (DiffernceO.Days > 0) {
                return DiffernceO.Days + ' days';
            }
            else if (DiffernceO.Hours > 0) {
                return DiffernceO.Hours + ' hours';
            }
            else if (DiffernceO.Minutes > 0) {
                return DiffernceO.Minutes + ' minutes';
            }
            else {
                return DiffernceO.Seconds + ' seconds';
            }
        }),
        ValidatePermissions: (function (PermissionsList) {
            $("[data-permission]").each(function () {
                if (PermissionsList != null) {
                    if (PermissionsList.UserRoles != undefined && PermissionsList.UserRoles != null && PermissionsList.UserRoles.length > 0) {
                        var PermissionName = $(this).data('permission');
                        var FoundCount = 0;
                        for (let index = 0; index < PermissionsList.UserRoles.length; index++) {
                            var PermissionRole = PermissionsList.UserRoles[index];
                            for (let kindex = 0; kindex < PermissionRole.RolePermissions.length; kindex++) {
                                var PermissionSystemName = PermissionRole.RolePermissions[kindex].SystemName;
                                if (PermissionSystemName != undefined && PermissionSystemName != null) {
                                    if (PermissionName == PermissionSystemName) {
                                        $(this).addClass('pvalid');
                                        FoundCount = 1;
                                    }
                                }
                            }
                        }
                        if (FoundCount == 1) {
                            $(this).removeClass('pnvalid');
                            $(this).addClass('pvalid');
                        }
                        else {
                            $(this).removeClass('pvalid');
                            $(this).addClass('pnvalid');
                        }
                        FoundCount = 0;
                    }
                }
            });
        }),
        ValidatePermission: (function (PermissionsList) {
            $("[data-permission]").each(function () {
                if (PermissionsList != null) {
                    if (PermissionsList != undefined && PermissionsList != null && PermissionsList.length > 0) {
                        var PermissionName = $(this).data('permission');
                        if (PermissionName != undefined && PermissionName != null && PermissionName != "") {
                            var ItemIndex = PermissionsList.indexOf(PermissionName);
                            if (ItemIndex > -1) {
                                $(this).removeClass('pnvalid');
                                $(this).addClass('pvalid');
                            }
                            else {
                                $(this).removeClass('pvalid');
                                $(this).addClass('pnvalid');
                            }
                        }
                        else {
                            $(this).removeClass('pvalid');
                            $(this).addClass('pnvalid');
                        }
                        // var FoundCount = 0;
                        // for (let index = 0; index < PermissionsList.length; index++) {
                        //     var PermissionRole = PermissionsList[index];
                        //     for (let kindex = 0; kindex < PermissionRole.RolePermissions.length; kindex++) {
                        //         var PermissionSystemName = PermissionRole.RolePermissions[kindex].SystemName;
                        //         if (PermissionSystemName != undefined && PermissionSystemName != null) {
                        //             if (PermissionName == PermissionSystemName) {
                        //                 $(this).addClass('pvalid');
                        //                 FoundCount = 1;
                        //             }
                        //         }
                        //     }
                        // }
                        // if (FoundCount == 1) {
                        //     $(this).removeClass('pnvalid');
                        //     $(this).addClass('pvalid');
                        // }
                        // else {
                        //     $(this).removeClass('pvalid');
                        //     $(this).addClass('pnvalid');
                        // }
                        // FoundCount = 0;
                    }
                    else {
                        $(this).removeClass('pvalid');
                        $(this).addClass('pnvalid');
                    }
                }
                else {
                    $(this).removeClass('pvalid');
                    $(this).addClass('pnvalid');
                }
            });
        }),
        SelectText(containerid) {
            document.getElementById(containerid).select();
            document.execCommand('Copy');
            // if (document.selection) {
            //     var range = document.body.createTextRange();
            //     range.moveToElementText(document.getElementById(containerid));
            //     range.select();
            // } else if (window.getSelection) {
            //     var range = document.createRange();
            //     range.selectNode(document.getElementById(containerid));
            //     window.getSelection().removeAllRanges();
            //     window.getSelection().addRange(range);
            // }
        },
        RefreshMenu: (function () {

            try {
                var sidebar = $('.page-sidebar');
                var sidebarWrapper = $('.page-sidebar .page-sidebar-wrapper');
                sidebar.find('li > a').on('click', function (e) {
                    if ($(this).next().hasClass('sub-menu') === false) {
                        return;
                    }
                    var parent = $(this).parent().parent();
                    parent.children('li.open').children('a').children('.arrow').removeClass('open');
                    parent.children('li.open').children('a').children('.arrow').removeClass('active');
                    parent.children('li.open').children('.sub-menu').slideUp(200);
                    parent.children('li').removeClass('open');

                    var sub = jQuery(this).next();
                    if (sub.is(":visible")) {
                        jQuery('.arrow', jQuery(this)).removeClass("open");
                        jQuery(this).parent().removeClass("active");
                        sub.slideUp(200, function () {
                        });
                    } else {
                        jQuery('.arrow', jQuery(this)).addClass("open");
                        jQuery(this).parent().addClass("open");
                        sub.slideDown(200, function () {
                        });
                    }
                    e.preventDefault();
                });
                //Auto close open menus in Condensed menu
                if (sidebar.hasClass('mini')) {
                    var elem = jQuery('.page-sidebar ul');
                    elem.children('li.open').children('a').children('.arrow').removeClass('open');
                    elem.children('li.open').children('a').children('.arrow').removeClass('active');
                    elem.children('li.open').children('.sub-menu').slideUp(200);
                    elem.children('li').removeClass('open');
                }
                $.fn.scrollbar && sidebarWrapper.scrollbar();

                $.fn.scrollbar && $('.scroller').each(function () {
                    var h = $(this).attr('data-height');
                    $(this).scrollbar({
                        ignoreMobile: true
                    });
                    if (h != null || h != "") {
                        if ($(this).parent('.scroll-wrapper').length > 0)
                            $(this).parent().css('max-height', h);
                        else
                            $(this).css('max-height', h);
                    }
                });
            } catch (error) {
                alert(error);
            }
        }),
    };
}());

