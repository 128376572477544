import { ChangeDetectorRef, Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataHelperService, HelperService, OResponse, OSelect } from '../../../service/service';
import { Address } from "ngx-google-places-autocomplete/objects/address";
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'registerstore',
    templateUrl: './registerstore.component.html',
})


export class RegisterStoreComponentV2 implements OnInit {
    @ViewChild('ngOtpInput') ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: '',
    inputStyles: {
      'width': '55px',
      'height': '30px',
      'margin-right':'8px'
    }
  };


    Form_AddUser_Processing = false;
    ShowCategorySelector: boolean = true;
    constructor(
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
        public _DataHelperService: DataHelperService,
        public _ChangeDetectorRef: ChangeDetectorRef
    ) {

    }
    ngOnInit() {
        this.Form_AddUser_Load();
        this.GetBusinessCategories();
        // this.TUTr_Filter_Banks_Load();
        // this.TUTr_Filter_Providers_Load();
    }


    _CurrentAddress: any = {};
    Form_AddUser: FormGroup;
    Form_AddUser_Load() {
        this.Form_AddUser = this._FormBuilder.group({
            OperationType: 'new',
            Task: this._HelperService.AppConfig.Api.Core.onboardmerchant,
            AccountOperationTypeCode: this._HelperService.AppConfig.AccountOperationType.Offline,
            Name: [null, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(256)])],
            // TerminalId: [null],
           // otp:[null, Validators.required],
            // EmailAddress: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(2)])],
            // MobileNumber: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(14)])],
            MobileNumber: [null, Validators.compose([Validators.required,Validators.minLength(8), Validators.maxLength(14)])],
            Description: null,
           // Address: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(256)])],
            StateName: null,
            CityName: null,
            CountryName: null,
            Latitude: null,
            Longitude: null,
            StatusCode: this._HelperService.AppConfig.Status.Active,
        });
    }
    // Form_AddUser_Process(_FormValue: any) {

    //     if (this.Form_AddUser_Latitude == 0 || this.Form_AddUser_Longitude == 0) {
    //         this._HelperService.NotifyError('Mark your business location on map to continue');
    //     }
    //     else {

    //         var Request = this.CreateRequestJson();
    //         // console.log("Number", this._HelperService.OnBoardMerchantStoreMobileNumber)
    //         // _FormValue.DisplayName = _FormValue.Name;
    //         // _FormValue.UserName = this._HelperService.GeneratePassoword();
    //         // _FormValue.Password = this._HelperService.GeneratePassoword();
    //         this.Form_AddUser_Processing = true;
    //         let _OResponse: Observable<OResponse>;
    //         _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Account, Request);
    //         _OResponse.subscribe(
    //             _Response => {
    //                 this.Form_AddUser_Processing = false;
    //                 if (_Response.Status == this._HelperService.StatusSuccess) {
    //                     this._HelperService.MerchantReferenceKey = _Response.Result.ReferenceKey;
    //                     this._HelperService.MerchantReferenceId = _Response.Result.ReferenceId;
    //                     this._HelperService.MerchantToken = _Response.Result.Token;
    //                     //         Password: this._HelperService.OnBoardMerchant.Password
    //                     this._HelperService.SaveStorage(
    //                         this._HelperService.AppConfig.Storage.StoreRegistrationData,
    //                         {
    //                             ReferenceKey: _Response.Result.ReferenceKey,
    //                             ReferenceId: _Response.Result.ReferenceId,
    //                             MerchantToken: _Response.Result.Token,
    //                             Email: this._HelperService.OnBoardMerchant.EmailAddress,
    //                             Password: this._HelperService.OnBoardMerchant.Password
    //                         }
    //                     );

    //                     // window.location.href = this._HelperService.AppConfig.Pages.System.MerchantRewardSetup;
    //                     this._Router.navigate([
    //                         this._HelperService.AppConfig.Pages.System.MerchantRewardSetup,

    //                     ]);
    //                 }
    //                 else {
    //                     this._HelperService.NotifyError(_Response.Message);
    //                 }
    //             },
    //             _Error => {
    //                 this.Form_AddUser_Processing = false;
    //                 this._HelperService.HandleException(_Error);
    //             });
    //     }
    // }


    Form_AddUser_Process(_FormValue: any) {
        console.log(_FormValue);
        //window.localStorage.setItem("formvalue",_FormValue);
        var Request = this.CreateRequestJson();
        // console.log("request",Request);
        
        // this._HelperService.SaveStorage(
        //                     this._HelperService.AppConfig.Storage.businessregister,
        //                     {
        //                         Name: _FormValue.Name,
        //                        Mobile:_FormValue.Mobile,
        //                        // Categories:Request
                             
        //                     }
        //                 );



        
        this._Router.navigate([
              this._HelperService.AppConfig.Pages.System.BLocation,
          ]);

            // var Request = this.CreateRequestJson();
            // // console.log("Number", this._HelperService.OnBoardMerchantStoreMobileNumber)
            // // _FormValue.DisplayName = _FormValue.Name;
            // // _FormValue.UserName = this._HelperService.GeneratePassoword();
            // // _FormValue.Password = this._HelperService.GeneratePassoword();
            // this.Form_AddUser_Processing = true;
            // let _OResponse: Observable<OResponse>;
            // _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Account, Request);
            // _OResponse.subscribe(
            //     _Response => {
            //         this.Form_AddUser_Processing = false;
            //         if (_Response.Status == this._HelperService.StatusSuccess) {
            //             this._HelperService.MerchantReferenceKey = _Response.Result.ReferenceKey;
            //             this._HelperService.MerchantReferenceId = _Response.Result.ReferenceId;
            //             this._HelperService.MerchantToken = _Response.Result.Token;
            //             //         Password: this._HelperService.OnBoardMerchant.Password
            //             this._HelperService.SaveStorage(
            //                 this._HelperService.AppConfig.Storage.StoreRegistrationData,
            //                 {
            //                     ReferenceKey: _Response.Result.ReferenceKey,
            //                     ReferenceId: _Response.Result.ReferenceId,
            //                     MerchantToken: _Response.Result.Token,
            //                     Email: this._HelperService.OnBoardMerchant.EmailAddress,
            //                     Password: this._HelperService.OnBoardMerchant.Password
            //                 }
            //             );

            //             // window.location.href = this._HelperService.AppConfig.Pages.System.MerchantRewardSetup;
            //             this._Router.navigate([
            //                 this._HelperService.AppConfig.Pages.System.MerchantRewardSetup,

            //             ]);
            //         }
            //         else {
            //             this._HelperService.NotifyError(_Response.Message);
            //         }
            //     },
            //     _Error => {
            //         this.Form_AddUser_Processing = false;
            //         this._HelperService.HandleException(_Error);
            //     });
        
    }
    Form_AddUser_Address: string = null;
    Form_AddUser_Latitude: number = 0;
    Form_AddUser_Longitude: number = 0;
    Form_AddUser_PlaceMarkerClick(event) {
        this.Form_AddUser_Latitude = event.coords.lat;
        this.Form_AddUser_Longitude = event.coords.lng;
    }
    public Form_AddUser_AddressChange(address: Address) {
        // this.Form_AddUser_Latitude = address.geometry.location.lat();
        // this.Form_AddUser_Longitude = address.geometry.location.lng();
        // this.Form_AddUser_Address = address.formatted_address;

        this.Form_AddUser_Latitude = address.geometry.location.lat();
        this.Form_AddUser_Longitude = address.geometry.location.lng();

        this.Form_AddUser_Address = address.formatted_address;
        this.Form_AddUser.controls['Address'].patchValue(address.formatted_address);
        this._CurrentAddress = this._HelperService.GoogleAddressArrayToJson(address.address_components);
        this.Form_AddUser.controls['CityName'].patchValue(this._CurrentAddress.sublocality_level_2);
        this.Form_AddUser.controls['StateName'].patchValue(this._CurrentAddress.sublocality_level_1);
        this.Form_AddUser.controls['CountryName'].patchValue(this._CurrentAddress.country);
        this.Form_AddUser.controls['Latitude'].patchValue(this.Form_AddUser_Latitude);
        this.Form_AddUser.controls['Longitude'].patchValue(this.Form_AddUser_Longitude);

        if(this._CurrentAddress.country = 'Nigeria'){
            this._HelperService.AppConfig.CurrencySymbolCustom = "&#8358;";

        }
        else{
            this._HelperService.AppConfig.CurrencySymbolCustom = "&#8373;";

        }
    }


    CreateRequestJson(): void {
        var AccountDetail = this._HelperService.OnBoardMerchant
            AccountDetail.Task = this._HelperService.AppConfig.Api.Core.onboardmerchant,
           // AccountDetail.Name = this.Form_AddUser.controls['Name'].value,
            AccountDetail.AccountOperationTypeCode = this.Form_AddUser.controls['AccountOperationTypeCode'].value,
            AccountDetail.StatusCode = this.Form_AddUser.controls['StatusCode'].value,
            // AccountDetail.EmailAddress = this.Form_AddUser.controls['EmailAddress'].value,
           AccountDetail.Mobile = this.Form_AddUser.controls['Mobile'].value,
          ///  AccountDetail.Description = this.Form_AddUser.controls['Description'].value,
            AccountDetail.Name = this.Form_AddUser.controls['Name'].value,
           // AccountDetail.WebsiteUrl = this.Form_AddUser.controls['WebsiteUrl'].value,

            AccountDetail.Address = {
               // Latitude: this.Form_AddUser_Latitude,
               // Longitude: this.Form_AddUser_Longitude,
               // Address: this.Form_AddUser.controls['Address'].value,
                // CityName: this._CurrentAddress.sublocality_level_2,
                // StateName: this._CurrentAddress.sublocality_level_1,
             //   CountryName: this._CurrentAddress.country
            };

        if (this.SelectedBusinessCategories != undefined && this.SelectedBusinessCategories.length > 0) {
            AccountDetail.Categories = []
            this.SelectedBusinessCategories.forEach(element => {
                AccountDetail.Categories.push(
                    {
                        ReferenceKey: element.key,
                        ReferenceId: element.id
                    }
                )
            });
        }
        console.log("AccountDetail",AccountDetail);

        this._HelperService.SaveStorage(
                                this._HelperService.AppConfig.Storage.businessregister,
                                {
                                    Name: AccountDetail,
                                  
                                 
                                }
                            );
        return AccountDetail;

        
    }

    CreateRequestOTPJson():void{
      //  AccountDetail.Mobile = this.Form_AddUser.controls['Mobile'].value,
    }


    public BusinessCategories = [];
    public S2BusinessCategories = [];

    GetBusinessCategories() {

        this._HelperService.ToggleField = true;
        var PData =
        {
            Task: this._HelperService.AppConfig.Api.Core.getcategories,
            SearchCondition: this._HelperService.GetSearchConditionStrict('', 'StatusCode', this._HelperService.AppConfig.DataType.Text, 'default.active', '='),
            SortExpression: 'Name asc',
            Offset: 0,
            Limit: 1000,
        }
        PData.SearchCondition = this._HelperService.GetSearchConditionStrict(
            PData.SearchCondition,
            "TypeCode",
            this._HelperService.AppConfig.DataType.Text,
            this._HelperService.AppConfig.HelperTypes.MerchantCategories,
            "="
        );
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Op, PData);
        _OResponse.subscribe(
            _Response => {
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    if (_Response.Result.Data != undefined) {
                        this.BusinessCategories = _Response.Result.Data;

                        this.ShowCategorySelector = false;
                        this._ChangeDetectorRef.detectChanges();
                        for (let index = 0; index < this.BusinessCategories.length; index++) {
                            const element = this.BusinessCategories[index];
                            this.S2BusinessCategories.push(
                                {
                                    id: element.ReferenceId,
                                    key: element.ReferenceKey,
                                    text: element.Name
                                }
                            );
                        }
                        this.ShowCategorySelector = true;
                        this._ChangeDetectorRef.detectChanges();

                        this._HelperService.ToggleField = false;

                    }
                }
                else {
                    this._HelperService.NotifyError(_Response.Message);
                }
            },
            _Error => {
                this._HelperService.IsFormProcessing = false;
                this._HelperService.HandleException(_Error);
                this._HelperService.ToggleField = false;

            });
    }
    public SelectedBusinessCategories = [];
    CategoriesSelected(Items) {

        if (Items != undefined && Items.value != undefined && Items.value.length > 0) {
            this.SelectedBusinessCategories = Items.data;
        }
        else {
            this.SelectedBusinessCategories = [];
        }


    }
    SaveMerchantBusinessCategory(item) {
        if (item != '0') {
            var Setup =
            {
                Task: this._HelperService.AppConfig.Api.Core.SaveUserParameter,
                TypeCode: this._HelperService.AppConfig.HelperTypes.MerchantCategories,
                // UserAccountKey: this._UserAccount.ReferenceKey,
                CommonKey: item,
                StatusCode: 'default.active'
            };
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V2.System, Setup);
            _OResponse.subscribe(
                _Response => {
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        this._HelperService.NotifySuccess('Business category assigned to merchant');
                        // this.BusinessCategories = [];
                        // this.GetMerchantBusinessCategories();
                    }
                    else {
                        this._HelperService.NotifyError(_Response.Message);
                    }
                },
                _Error => {
                    this._HelperService.IsFormProcessing = false;
                    this._HelperService.HandleException(_Error);
                });
        }
    }


    public TUTr_Filter_Bank_Option: Select2Options;
  TUTr_Filter_Banks_Load() {
    var PlaceHolder = this._HelperService.AppConfig.CommonResource.Select;
    var _Select: OSelect = {
      Task: this._HelperService.AppConfig.Api.Core.GetUserAccountsLite,
      Location: this._HelperService.AppConfig.NetworkLocation.V2.System,
      SearchCondition: "",
      SortCondition: [],
      Fields: [
        {
          SystemName: "ReferenceKey",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: true,
          Text: false,
        },
        {
          SystemName: "DisplayName",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: false,
          Text: true
        },
        {
          SystemName: "AccountTypeCode",
          Type: this._HelperService.AppConfig.DataType.Text,
          SearchCondition: "=",
          SearchValue: this._HelperService.AppConfig.AccountType.Acquirer
        }]
    };
    var _Transport = this._DataHelperService.S2_BuildList(_Select) as any;
    this.TUTr_Filter_Bank_Option = {
      placeholder: 'Select Bank (Acquirer)',
      ajax: _Transport,
      multiple: false,
      allowClear: true,
    };
  }
  TUTr_Filter_Banks_Change(event: any) {
    this.Form_AddUser.patchValue(
      {
        AcquirerReferenceId: event.data[0].ReferenceId,
        AcquirerReferenceKey: event.data[0].ReferenceKey,
      });
  }
  public TUTr_Filter_Provider_Option: Select2Options;
  TUTr_Filter_Providers_Load() {
    var PlaceHolder = this._HelperService.AppConfig.CommonResource.Select;
    var _Select: OSelect = {
      Task: this._HelperService.AppConfig.Api.Core.GetUserAccountsLite,
      Location: this._HelperService.AppConfig.NetworkLocation.V2.System,
      SearchCondition: "",
      SortCondition: [],
      Fields: [
        {
          SystemName: "ReferenceKey",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: true,
          Text: false,
        },
        {
          SystemName: "DisplayName",
          Type: this._HelperService.AppConfig.DataType.Text,
          Id: false,
          Text: true
        },
        {
          SystemName: "AccountTypeCode",
          Type: this._HelperService.AppConfig.DataType.Text,
          SearchCondition: "=",
          SearchValue: this._HelperService.AppConfig.AccountType.PosAccount
        }]
    };


    var _Transport = this._DataHelperService.S2_BuildList(_Select) as any;
    this.TUTr_Filter_Provider_Option = {
      placeholder: 'Select  Provider (PTSP)',
      ajax: _Transport,
      multiple: false,
      allowClear: true,
    };
  }
  TUTr_Filter_Providers_Change(event: any) {
    this.Form_AddUser.patchValue(
      {
        ProviderReferenceId: event.data[0].ReferenceId,
        ProviderReferenceKey: event.data[0].ReferenceKey,
      });
  }

  otpreceived: boolean = true;
  verifyotp(): void {
    var Request = this.CreateRequestJson();
    this.Form_AddUser_Processing = true;
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Requestotp, Request);
    _OResponse.subscribe(
        _Response => {
            this.Form_AddUser_Processing = false;
        })
    this.otpreceived = false;
  }
  

 Addbusinessdetails()
 {
    this._Router.navigate([
        this._HelperService.AppConfig.Pages.System.BLocation,

    ]);
 }

}
