import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataHelperService, HelperService, OResponse, OSelect } from '../../../service/service';
import * as Feather from "feather-icons";
declare var $: any;

@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html'
})
export class checkmailComponent implements OnInit {
  emailid = null;
  mailid: string = ""
  constructor(public _Router: Router,
    public _HelperService: HelperService,
    public _ActivatedRoute: ActivatedRoute,

  ) {

    this.mailid = ""
  }

  URLData: any = {

    Code: null
  };
  Response: any = {
    Reference: null
  }

  ngOnInit() {
    Feather.replace();
    this.emailid = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.VerifyEmail)
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)

    this._ActivatedRoute.params.subscribe((params: Params) => {
      this.URLData.Code = params.referencecode + "==";

      // this.VerifyMail(this.URLData.Code);

    });
  }
  RouterToMail() {
    this.emailid = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.VerifyEmail)

    $("#onb-open-mailbox").click((event) => {
      event.preventDefault(); // prevent the event , ie form submit event
      let mail_extension = this.emailid.split("@");
      switch (mail_extension[mail_extension.length - 1]) {
        case "gmail.com":
          location.href = "https://mail.google.com";
          break;
        case "zoho.com":
          location.href = "https://mail.zoho.com";
          break;
        case "halcyonminds.com":
          location.href = "https://mail.zoho.com";
          break;
        case "yahoo.com":
          location.href = "https://mail.yahoo.com";
          break;
        case "rediff.com":
          location.href = "https://mail.rediff.com/cgi-bin/login.cgi";
          break;
        default:
          location.href = "https://mail.google.com";
      }
    });

  }

  nextpage() {
    this._Router.navigate([
      this._HelperService.AppConfig.Pages.System.MerchantSetup,

    ]);
  }


  public VerifyMail(value): void {
    this._HelperService.IsFormProcessing = true;
    this._HelperService.AppConfig.ShowHeader = true;
    var pData = {
      Task: "onboardmerchant_st3",
      Reference: this.Response.Result.Reference,
      Source: 'Merchant',
      Token: value,
      Host: this._HelperService.AppConfig.Host

      // Token:this.ngOtpInputRef.getValue(),
    };
    pData.Reference = this.Response.Result.Reference;
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._HelperService.IsFormProcessing = false;

          this._Router.navigate([
            this._HelperService.AppConfig.Pages.System.MerchantSetup,

          ]);

        } else {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      }
    );
  }


  public ResendMail(): void {
    this.Response = this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.DataReference)

    this._HelperService.IsFormProcessing = true;
    this._HelperService.AppConfig.ShowHeader = true;
    var pData = {
      Task: "onboardmerchant_st2",
      Reference: this.Response.Result.Reference,
      Source: 'Merchant',
      // Token:this.ngOtpInputRef.getValue(),
      Host: this._HelperService.AppConfig.Host

    };
    pData.Reference = this.Response.Result.Reference;
    let _OResponse: Observable<OResponse>;
    _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.MOnBoard, pData);
    _OResponse.subscribe(
      _Response => {
        if (_Response.Status == this._HelperService.StatusSuccess) {
          this._HelperService.IsFormProcessing = false;

        } else {
          this._HelperService.IsFormProcessing = false;
          this._HelperService.NotifyError(_Response.Message);
        }
      },
      _Error => {
        this._HelperService.HandleException(_Error);
      }
    );
  }

}
