import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantguardGuard implements CanActivate, CanActivateChild {

  constructor(public _HelperService: HelperService, public _Router: Router
  ) {

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    switch (state.url) {
      case "/m/visits": {
        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'loyalty');
          if (RData) {
            return true;
          } else {
            return false;
          }
        }


      }

        break;

      default:
        break;
    }


    this._HelperService.SalesPerformances = true;
    return true;
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // console.log(next);

    switch (state.url) {
      case "/m/dashboard": {
        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          if (RData) {
            return true;

          } else {
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Loyality.Overview]);
         

          }
        }

      }

        break;
      case "/m/products": {
        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          if (RData) {
            return true;
          } else {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);
         
            return false;
          }

        }


      }

        break;
      case "/m/salesummary": {
        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          if (RData) {
            return true;
          } else {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

            return false;
          }
        }



      }

        break;
      case "/m/salestrend": {
        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          if (RData) {
            return true;
          } else {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

            return false;
          }
        }


      }

        break;
      case "/m/sales/saleshistory": {
        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          if (RData) {
            return true;
          } else {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

            return false;
          }
        }


      }

        break;
      case "/m/bank": {

        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          if (RData) {
            return true;
          } else {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

            return false;
          }
        }


      }

        break;
      case "/m/orders": {

        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);

          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          if (RData) {
            return true;
          } else {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

            return false;
          }
        }


      }


        break;
      case "/m/giftpoints": {

        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);

          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          if (RData) {
            return true;
          } else {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

            return false;
          }
        }


      }

        break;
      case "/m/giftpointsales/rewardhistory": {

        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          if (RData) {
            return true;
          } else {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

            return false;
          }

        }


      }

        break; case "/m/giftpointsales/redeemhistory": {

          if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

            return false;
          } else {
            var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
            if (RData) {
              return true;
            } else {
        
              // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
              this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

              return false;
            }

          }



        }

        break;
      case "/m/giftpointsales/balancehistory": {


        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');
          if (RData) {
            return true;
          } else {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

            return false;
          }
        }


      }

        break;
      case "/m/cashieranalyis": {


        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

          return false;
        } else {
          var RData: any = this._HelperService.UserAccount.Permissions.find(x => x['SystemName'] == 'salesanalytics');

          if (RData) {
            return true;
          } else {
            // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
            this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);

            return false;
          }
        }


      }

        break;

      case "/m/overview": {
        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);
       
          return false;
        }
        else {
          return true;

        }

      }
      break
      case "/m/settings/topup": {
        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);
       
          return false;
        }
        else {
          return true;

        }

      }
      break
      case "/m/settings/stores": {
        if (this._HelperService.UserAccount.SystemSubscription.IsSubscriptionActive == false && this._HelperService.AppConfig.Host != 'merchantrewards.interswitch.com'  &&  this._HelperService.AppConfig.Host != 'interswitch.thankucash.com') {
          // this._Router.navigate(['m' + '/' + this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.Upgrade]);
          this._Router.navigate([this._HelperService.AppConfig.Pages.ThankUCash.PanelMerchant.MerchantTSubscription]);
       
          return false;
        }
        else {
          return true;

        }

      }


        break;

      default:
        break;
    }

    this._HelperService.SalesPerformances = true;
    return true;
  }
}