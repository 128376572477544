import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as Feather from 'feather-icons';

import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Observable, Subscription } from "rxjs";
import { DataHelperService, HelperService, OResponse, OSelect, OUserDetails, FilterHelperService } from "../../service/service";
import { InputFileComponent, InputFile } from 'ngx-input-file';
declare let $: any;
import swal from "sweetalert2";

@Component({
  selector: "tu-subscription",
  templateUrl: "./tusubscription.component.html"
})
export class TUSubScriptionComponent {
 

  //#endregion

  constructor(
    public _HelperService: HelperService,
  ) {

  }

  ngOnInit() {
    Feather.replace();
  //  this._ShowsubscriptionPreview();

  }

 
  private selectedLink: string="Monthly";        
  
  setradio(e: string): void   
  {  
  console.log(e);
    this.selectedLink = e;  
          
  }  
  

  _ShowsubscriptionPreview() {
    this._HelperService.OpenModal('Monthly');
}


subscription_Close() {
  
}

public _AccountBalanceCreditAmount = 0;
public _TransactionReference = null;
public TransactionId
OpenPaymentOptions() {
  this._AccountBalanceCreditAmount = 0;
  var Ref = this.generateRandomNumber();
  this._TransactionReference = 'Tp' + '_' + this._HelperService.UserAccount.AccountId + '_' + Ref;
  this._HelperService.OpenModal('Form_AddUser_Content');
}

generateRandomNumber() {
  var TrRef = Math.floor(100000 + Math.random() * (999999 + 1 - 100000));
  return TrRef;
}

paymentDone(ref: any) {
console.log(ref);
  this.TransactionId = ref.trans
  
  if (ref != undefined && ref != null && ref != '') {
    if (ref.status == 'success') {
      this.CreditAmount();
    }
    else {
      this._HelperService.NotifyError('Payment failed');
    }
  }
  else {
    this._HelperService.NotifyError('Payment failed');
  }
  // this.title = 'Payment successfull';
  // console.log(this.title, ref);
}


public CreditAmount() {
  this._HelperService.IsFormProcessing = true;
  var PostData = {
    Task: "topupaccount",
    AccountKey: this._HelperService.AppConfig.ActiveOwnerKey,
    AccountId: this._HelperService.AppConfig.ActiveOwnerId,
    Amount: this._AccountBalanceCreditAmount,
    PaymentReference: this._TransactionReference,
    TransactionId: this.TransactionId
  };
  let _OResponse: Observable<OResponse>;
  _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V3.Subscription, PostData);
  _OResponse.subscribe(
    _Response => {
      this._HelperService.IsFormProcessing = false;
      if (_Response.Status == this._HelperService.StatusSuccess) {
        this._AccountBalanceCreditAmount = 0;
        this._TransactionReference = null;
        this._HelperService.NotifySuccess("Account credited");
        //this.GetBalance();


      } else {
        this._HelperService.NotifySuccess(_Response.Message);
      }
    },
    _Error => {
      this._HelperService.IsFormProcessing = false;
      this._HelperService.HandleException(_Error);
    }
  );
}

}


